
<template>
  <div>
    <el-button size="mini" @click="openSetting">
      <i class="el-icon-setting"></i> 打开设置窗口
    </el-button>
    <el-dialog
      title="定价策略设置"
      :visible.sync="open"
      width="80%"
      v-if="open"
      append-to-body
      :close="handleClose"
    >
      <div
        class="flex flex-row flex-nowrap flex-middle margin-bottom-10"
        v-for="(item, $index) in values"
        :key="$index"
      >
        <div class="flex-1">
          <el-select
            class="width-100-percent"
            v-model="item.rule"
            multiple
            placeholder="请选择策略组合条件"
          >
            <el-option
              v-for="option in options"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="margin-left-10 margin-right-10">
          <el-checkbox v-model="item.enable" :true-label="1" :false-label="0"
            >启用</el-checkbox
          >
        </div>
        <div>
          <el-button @click="up($index)">上移</el-button
          ><el-button @click="down($index)">下移</el-button
          ><el-button type="danger" @click="remove($index)">删除</el-button>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <div class="flex felx-row flex-space-between">
          <div>
            <el-button class="margin-right-10" @click="add">新增策略</el-button
            >策略的优先级为排序，排序越前，优先级越高，可通过上下移调整排序
          </div>
          <div>
            <el-button @click="open = false">取 消</el-button>
            <el-button type="primary" @click="sure">确 定</el-button>
          </div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { isEmpty, arraySwitch, showWarningToast } from '@/utils'
export default {
  props: [
    "item",
    "dataSource",
    "readonly",
    "disabled",
    "itemValidate",
    "handleChangeData"
  ],
  data () {
    return {
      open: false,
      values: [],
      options: [
        { value: 'department_id', label: '部门' },
        { value: 'stockcate_id', label: '存货分类' },
        { value: 'lustre_id', label: '成色' },
        { value: 'workshop_id', label: '生产线' },
        { value: 'process_stage_id', label: '工艺阶段' },
      ]
    };
  },
  methods: {
    handleClose () {
    },
    openSetting () {
      if (typeof this.dataSource[this.item.data] === "string" && !isEmpty(this.dataSource[this.item.data])) {
        this.values = JSON.parse(this.dataSource[this.item.data])
      } else {
        this.values = [{ enable: 1 }]
      }

      this.open = true;
    },
    add () {
      this.values.push({ enable: 1 })
    },
    remove (index) {
      this.values.splice(index, 1)
    },
    up (index) {
      if (index === 0) {
        return
      }
      this.values = arraySwitch(this.values, index, index - 1)
    },
    down (index) {
      if (index === this.values.length - 1) {
        return
      }
      this.values = arraySwitch(this.values, index, index + 1)
    },
    sure () {
      let index = 0;
      for (let item of this.values) {
        if (!item.rule || !item.rule.length) {
          showWarningToast(`第${index + 1}行策略组合不能为空`)
          return
        }
        index++
      }
      this.handleChangeData(JSON.stringify(this.values));
      this.open = false;
    }
  }
};
</script>
<style scoped>
</style>
