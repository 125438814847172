import { http,parseTime } from "~utils";
import { saveTokenInfo, apiHttp} from "~utils/query/token";
import axios from "axios";
// let configMock = require("../../../static/config-mock.json");
// let refreshUserInfoMock = require("../../../static/userInfo/refreshUserInfoUrl.json");
export function getV () {
  let v = ''
  if (window.localStorage['ngStorage-stateMenuVer']) {
    v = window.localStorage['ngStorage-stateMenuVer']
  }
  return v
}
export default {
  setConfig({ commit, dispatch }) {
    let v = getV()
    if (v) {
      v = `?v=${v}`
    }
    return new Promise((resolve, reject) => {
      http({
        method: 'get',
        noToUrl: true,
        url:
          (process.env.NODE_ENV === "production"
            ? "config.php"
            : "../static/config.json") + v,
        noErrorDialog: true
      }).then(
        data => {
          commit("setConfig", { data: data });
          commit("setComponents", { data: data }); // 只加载有权限的组件
          commit("file/setFileConfig", { data: data });
          axios.defaults.headers.common["x-csrf-token"] = data._csrf;
          // axios.defaults.baseURL = data.baseUrl; // 手动进行 toUrl
          // dispatch("getMenu");

          // 线上，保存最新token信息
          if(process.env.NODE_ENV === "production"){
            saveTokenInfo(data.user.base.accessToken,data.user.base.accessTokenExpire,data.url.getAccessToken,data.orderApiURL).then(()=>{
              resolve(data);
            },()=>{
              resolve(data);
            },)
          } else{
            // 本地环境，token手动输入，不更新token
            resolve(data);
          }

        },
        errData => {
          // 登录状态校验失败特殊处理
          commit("setConfig", { data: errData });
          reject(errData.msg);
        }
      );
    });
  },
  setUserHomeTab({ state, commit }, { data }) {
    return new Promise((resolve, reject) => {
      // http({
      //   url: state.config.url.setUserHomeTab,
      //   data: {
      //     show: data
      //   }
      apiHttp({
        url: `params/userset`, // 个人参数
        method: 'get',
        params: {
          'corp_id': localStorage.getItem('corpId'),
          'id': 'homeConfig',
          'value': data ? JSON.stringify(data) : '',
        }
      }).then(
        res => {
          commit("setUserHomeTabShow", { data: data });
          resolve(res);
        },
        err => {
          // 登录状态校验失败特殊处理
          reject(err);
        }
      );
    });
  },
  editUserInfo({ state, dispatch, commit }, {userInfo,postData}) {
    return new Promise((resolve, reject) => {
      http({
        url: state.config.url.userInfo.editUserInfoUrl,
        data: postData
      }).then(
        data => {
          // 密码改回为 default 再存vuex
          userInfo.password = 'default'
          userInfo.repassword = 'default'
          commit("editUserInfo", userInfo);
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  },
  wxUnbind({ state, dispatch, commit }) {
    return new Promise((resolve, reject) => {
      http({
        url: state.config.url.userInfo.wxUnbindUrl
      }).then(
        data => {
          commit("wxUnbind");
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  },
  refreshUserInfo({ state, dispatch, commit }) {
    return new Promise((resolve, reject) => {
      http({
        url: state.config.url.userInfo.refreshUserInfoUrl,
        method: "get"
      }).then(
        data => {
          commit("refreshUserInfo", data.data);
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  },
  editCorp({ state, dispatch, commit }, corp) {
    return new Promise((resolve, reject) => {
      http({
        url: state.config.url.corpSelected.SelectedUrl,
        data: {
          corp_id: corp.id,
          logindate: parseTime(null, '{y}-{m}-{d}'),
        }
      }).then(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
};
