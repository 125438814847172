<template>
  <a class="logo-wrap" :style="{ height: height + 'px' }">
    <!-- <img src="../assets/images/hiram.png" :style="{height: height + 'px'}" class="logo" alt="logo" /> -->
    <h1 :style="{
    fontSize: height / 2 + 'px',
    height: height + 'px',
    lineHeight: height + 'px',
  }">{{ title || config.title }}</h1>
  </a>
</template>
<script>
import { parseQueryString } from '@/utils'
import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    height: {
      type: Number,
      default: 60
    },
    title: ''
  },
  computed: {
    ...mapState(['config'])
  },
  created () {
    // 为个个模块增加登陆页面 显示不同的title 与 跳转不同的页面
    let params = parseQueryString()
    if (params && params.state) {
      let url
      switch (params.state) {
        case 'gallery':
          this.title = '产品图库管理系统'
          url = '/product/index.html#/gallery'
          break
        case 'weighing':
          this.title = '包装称重系统'
          url = '/apps/weighing/index.html'
          break
        case 'verify':
          this.title = '核销管理系统'
          url = '/order/index.html?t=verifyList#/list/verifyDetail'
          break
        case 'process':
          this.title = '工序管理系统'
          url = '/order/index.html?t=processOrderList#/list/processOrderDetail'
          break
      }

      this.setTitle(this.title)
      this.setRedirectUrlAfterLogin(url)
    }
  },
  methods: {
    ...mapMutations(['setTitle', 'setRedirectUrlAfterLogin'])
  }
}
</script>

<!-- <style lang="less" scoped>
.logo-wrap {
  display: inline-flex;
  text-align: center;
  justify-content: center;

  h1 {
    color: #176fc7;
    margin-left: 10px;
  }

  .logo {
    height: 60px;
  }
}
</style> -->

<style>
.logo-wrap {
  display: inline-flex;
  text-align: center;
  justify-content: center;
}

.logo-wrap h1 {
  color: #176fc7;
  margin-left: 10px;
}

.logo-wrap .logo {
  height: 60px;
}
</style>
