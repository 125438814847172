<!-- 文本框 -->
<template>
    <el-date-picker :item="item"
      placeholder="选择日期"
      type="date"
      :dataSource="dataSource"
      :readonly="readonly"
      :disabled="disabled"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      v-model="dataSource[item.data]">
    </el-date-picker>

</template>

<script>
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'itemValidate'],
  data () {
    return {
    }
  },

  computed: {
  },

  methods: {}
}

</script>
<style scoped>
</style>
