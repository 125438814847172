var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tools && _vm.tools.length !== 0)?_c('div',[_c('div',{staticClass:"container",style:({
      bottom: _vm.show && _vm.userShow ? '0' : '-300px',
    })},[_c('div',{staticClass:"dock"},[_c('ul',_vm._l((_vm.tools),function(item,key){return _c('li',{key:key,class:{
            near:
              _vm.hover !== undefined
                ? _vm.hover && Math.abs(key - _vm.hover) === 1
                : _vm.selected && Math.abs(key - _vm.select) === 1,
          },on:{"mouseover":function($event){_vm.hover = key},"mouseout":function($event){_vm.hover = undefined}}},[_c('a',{on:{"click":function($event){return _vm.goToToolUrl(item.url)}}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"dock-item"},[_c('svg-icon',{attrs:{"icon-class":item.icon}})],1)])])}),0)])]),_c('div',{staticClass:"upWard",style:(!_vm.rotate ? 'box-shadow: none' : ''),on:{"click":_vm.handleToolsToggle}},[_c('div',{staticClass:"dock",class:[_vm.rotate ? 'normalIcon' : 'rotateIcon']},[_c('svg-icon',{staticStyle:{"width":"3em","height":"4em","color":"#409eff"},attrs:{"icon-class":"arrow_up"}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }