<template>
    <div>
        交货日期变更为 {{info.delivery_at | dateFilter}}{{info.is_urgent ? ", 并加急" : ""}}
    </div>
</template>

<script>
import { parseTime } from '@/utils'

export default {
    props: {
        info: Object
    },
    filters: {
        dateFilter (date) {
            return parseTime(date, '{y}-{m}-{d}')
        }
    }
}
</script>
