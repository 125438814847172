<template>
  <div class="navbar height-100-percent flex flex-row flex-middle">
    <div class="flex-1">
      <div
        class="left-item pull-left padding-left-10"
        style="display: flex; align-items: center"
      >
        <!-- <Logo :height="34"
          style="cursor:pointer"></Logo> -->
        <firmTitle />
        <el-popover
          placement="top-start"
          width="200"
          trigger="hover"
          v-if="corpId"
        >
          <div v-if="qrcodeCover || weappQrcodeUrl" class="weapp-qrcode-wrap">
            <img :src="qrcodeCover || weappQrcodeUrl" alt="" />
            <div v-if="qrcodeCover">
              公司版商家小程序
              <a
                :href="qrcodeCover"
                target="_blank"
                :download="corpQrcodeFileName"
                ><svg-icon icon-class="link"></svg-icon
              ></a>
            </div>
            <div v-else-if="canDiyQrcodeCover">
              前往
              <el-button type="text" @click="jumpCorpCorp"
                >[ 公司管理 ]</el-button
              >
              自定义图标
            </div>
          </div>
          <svg-icon
            icon-class="weapp"
            slot="reference"
            class="weapp-qrcode-outer-icon"
          ></svg-icon>
        </el-popover>
        <Search></Search>
      </div>
      <div
        class="right-item pull-right padding-right-20"
        style="display: flex; align-items: center"
      >
        <message-notice></message-notice>
        <gold-price></gold-price>
        <!-- <corp-selected></corp-selected> -->
        <user-info></user-info>
      </div>
    </div>
  </div>
</template>

<script>
import userInfo from './components/userInfo.vue'
import Logo from '@/components/logo.vue'
import firmTitle from '@/components/firmTitle.vue'
// import corpSelected from './components/corpSelected'
import Search from './components/searchApi.vue'
import goldPrice from './components/goldPrice.vue'
import { isDev } from '@/utils/env'
import { apiHttpGetAllResult } from '@/utils/query/token'
import { toUrl } from '@/utils'
import messageNotice from './components/messageNotice.vue'

export default {
  components: {
    Logo,
    // corpSelected,
    Search,
    userInfo,
    goldPrice,
    firmTitle,
    messageNotice
  },
  computed: {
    corpQrcodeFileName () {
      const corpName = localStorage.getItem('corpName') // 订单系统
      return corpName + ` - 公司版商家小程序`
    },
    corpId () {
      return this.$store.state.config.user.corp.corp_id
    },
    qrcodeCover () {
      return this.$store.state.config.user.corp.qrcode_cover
    },
    // 仅正式库和本地显示
    isDdgoldOrTest () {
      return location.origin.includes('master.hiram.cn') || location.origin.includes('ddgold.cn') || location.origin.includes('localhost') || location.origin.includes('127.0.0.1')
    },
    canDiyQrcodeCover () {
      return true
    }
  },
  data () {
    return {
      isDev,
      // 暂时使用固定图片
      weappQrcodeUrl: ''
    }
  },
  methods: {
    handleClick () {
      this.$router.push({ name: 'home' })
    },
    async getWeappQrcode () {
      // 有图返回自己
      if (this.qrcodeCover) {
        return this.qrcodeCover
      }

      // 无图加载该图
      const _corpId = this.corpId // 订单系统
      const res = await apiHttpGetAllResult({
        url: `files/corp/getsaleqrcode?id=${_corpId}&url=${'pages/icorp/main'}&v=${+new Date()}`,
        method: 'get',
        responseType: 'blob'
      })
      if (res) {
        var reader = new FileReader()
        reader.readAsDataURL(res)
        reader.addEventListener('load', () => {
          this.weappQrcodeUrl = reader.result
        }, false)
      }
    },
    jumpCorpCorp () {
      window.open(toUrl('/file/index.html#/menu/corp-corp'))
    }
  },
  mounted () {
    // 仅正式库和本地显示
    this.corpId && this.getWeappQrcode()
  }

}
</script>

<style>
.navbar {
  background: #fff;
}
.left-item {
  margin-top: 3px;
}
.right-item {
  margin-top: 6px;
}
/* @media screen and (min-width: 894px) {
  .right-item {
    height: 42px;
  }
  .left-item {
    height: 42px;
  }
} */
.weapp-qrcode-wrap {
  text-align: center;
}
.weapp-qrcode-wrap > img {
  margin-bottom: 10px;
  width: 200px;
}
.weapp-qrcode-outer-icon {
  font-size: 20px;
  margin-left: 16px;
  cursor: pointer;
  color: #999;
}
</style>
