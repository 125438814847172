// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import createApp from "./create-app";
import { homeRoutes, authRoutes, lastRoutes } from "./router/routes";
import bus from "@/utils/bus";
import _ from "lodash";
import "@/icons/index";

// console.log('hello')
// 创建vue实例
const { app, router, store } = createApp();

// 整个项目初始化的时候 请求config参数将全局参数存入store
router.onReady(() => {
  store
    .dispatch("setConfig")
    .then(() => {
      getLoginSuccessRoutes()
      app.$mount("#app"); // 挂载到html的div
    })
    .catch(() => {
      // 只有login
      app.$mount("#app"); // 挂载到html的div
    });
});

export function getApp () {
  return { app, router, store };
}

bus.$on("loginSuccess", getLoginSuccessRoutes);

function getLoginSuccessRoutes () {
  // console.log('getLoginSuccessRoutes');
  // 挂载登录成功的
  router.addRoute(...homeRoutes);

  if (store.state.components && !_.isEmpty(store.state.components)) {
    for (const key in store.state.components) {
      if (store.state.components.hasOwnProperty(key)) {
        router.addRoute(...authRoutes[key]);
      }
    }
  }
  router.addRoute(...lastRoutes);
}
