<!-- dialog盒子，取消、确定按钮 -->
<template>
  <div>

    <el-dialog :title="title"
      :visible.sync="dialogShow"
      :before-close="handleDialogClose"
      style="text-align: left;"
      :show-close="showClose"
      :width="width"
      v-bind="$attrs"
      :top="top">

      <div>
        <slot></slot>
      </div>
      <template v-if="showBtn">

        <div style="height: 1px;background: #eee;margin:12px 0;"></div>

        <div style="text-align:right">
          <span @click="handleDialogClose"
            style="margin-bottom:8px;cursor:pointer">
            <el-button>取消</el-button>
          </span>
          <span @click="handleDialogEdit"
            style="margin-bottom:8px;cursor:pointer">
            <el-button type="primary">确定
            </el-button>
          </span>
        </div>
      </template>

    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showClose: {
      type: Boolean,
      default: true
    },
    showBtn: {
      type: Boolean,
      default: false
    },
    top: {
      type: String,
      default: '80px'
    },
    title: {
      type: String
    },
    width: {
      type: String,
      default: '600px'
    },
    handleClose: {
      type: Function,
      default: () => { return true }
    },
    handleEdit: {
      type: Function
    }
  },

  data () {
    return {
      dialogShow: false
    }
  },

  components: {},

  computed: {},

  mounted () { },

  methods: {
    handleDialogClose (done) {
      if (this.handleClose()) {
        this.hide()
      }
    },

    handleDialogEdit (done) {
      this.handleEdit()
    },

    show () {
      this.dialogShow = true
    },
    hide () {
      this.dialogShow = false
    }
  }
}

</script>
<style scoped>
</style>
