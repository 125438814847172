<!-- 文本框 -->
<template >
  <div @click.prevent="handleJump('div')">
    <a class="href-link" :href="href" target="_blank" @click.prevent="handleJump('a')">{{data}}</a>
  </div>
</template>

<script>
import {toUrl,windowOpenUrl} from '~utils'
import {getNewUrl} from '~utils/newUrl'

export default {
  props: ['scope', 'item', 'data', 'full'],
  data() {
    return {
    }
  },

  computed: {
    href() {
      let state = this.getState()
      let newUrl = getNewUrl(state)
      if(state === 'salesList'){
        // return toUrl(`/saleForSalesman/index.html#/app/list`)
        return toUrl(`/order/index.html?t=sale#/saleOrder/${this.data}`)
      } else if(state){
        // return toUrl(`/index.php#/app/${state}?code=${this.data}`)
        return toUrl(newUrl + this.data)

      } else {
        return toUrl(this.data)
      }
    }
  },

  methods: {
    handleJump(type){
      if((type === 'div' && this.full) || (type === 'a' &&　!this.full)){
        this.handleClick()
      }
    },
    getState(){
      let state = ''
      // 带 code 的才进行穿透
      if (this.item.data.indexOf('code') > -1) {
        const row = this.scope.row
        // 获取到上游单据的类型
        const order_type_code = row.order_type_code // 本订单的类型
        const up_order_type_code = row.up_order_type_code // 上游订单的类型
        const down_order_type_code = row.down_order_type_code // 上游订单的类型
        const sale_order_type_code = row.sale_order_type_code // 销售订单的类型

        let valueToCheck = this.data // 原来检测类型的值

        // 存在新返回字段 order_type_code ，使用新模式
        if(order_type_code){
          // 使用 order_type_code 作为判断依据
          if(this.item.data === 'up_order_code'){
            valueToCheck = up_order_type_code
          } else if(this.item.data === 'down_order_code'){
            valueToCheck = down_order_type_code
          } else if(this.item.data === 'sale_order_code'){
            valueToCheck = sale_order_type_code
          } else if (this.item.data === 'verify_code') {
            valueToCheck = 'HX'
          }  else {
            valueToCheck = order_type_code
          }
        }

        if(!valueToCheck){
          // 如果 up_order_type_code 或 order_type_code 中没能拿到值，使用旧的判断方法
          valueToCheck = this.data
        }

        if (/^DD/.test(valueToCheck)) {
          state = 'order'
        } else if (/^FD/.test(valueToCheck)) { //草稿单
          state = 'productionorder'
        } else if (/^SD/.test(valueToCheck)) { //单品
          state = 'singleorder'
        } else if (/^SF/.test(valueToCheck)) {//单品*
          state = 'singledraft'
        } else if (/^SA/.test(valueToCheck)) {//销售订单列表
          state = 'salesList'
        } else if (/^HX/.test(valueToCheck)) {//销售订单列表
          state = 'orderverify'
        } else {
          state = 'order'
        }
      }
      return state
    },

    handleClick(){
      let state = this.getState()
      let newUrl = getNewUrl(state)

      if(state === 'salesList'){
        // windowOpenUrl(toUrl(`/saleForSalesman/index.html#/app/list`))
        windowOpenUrl(toUrl(`/order/index.html?t=sale#/saleOrder/${this.data}`), state + this.data)
      } else if(state){
        // windowOpenUrl(toUrl(`/index.php#/app/${state}?code=${this.data}`), state + this.data)
        windowOpenUrl(toUrl(newUrl + this.data), state + this.data)
      } else {
        windowOpenUrl(toUrl(this.data))
      }
    }
  }
}

</script>
<style scoped>
</style>
