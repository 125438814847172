<template>
  <el-main>
    <div class="center">
      <div class="qrcode">
        <div id="qrcode" ref="qrcode"></div>
      </div>
    </div>
    <div class="center">
      {{ msg }}
    </div>
    <div class="center" v-if="showUrl">
      {{ qrcodeUrl }}
    </div>
  </el-main>

</template>
<script>
import { mapState, mapActions } from 'vuex'
import QRCode from 'qrcodejs2-fix'

export default {
  name: 'addSalesman',
  props: {
    qrcodeUrl: String,
    msg: String,
    showUrl: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState([])
  },
  methods: {
    ...mapActions([]),
    makeQrcode () {
      new QRCode('qrcode', {
        width: 250,
        height: 250,
        text: this.qrcodeUrl
      })
    }
  },
  mounted () {
    this.makeQrcode()
  },
  watch: {
    qrcodeUrl () {
      this.$refs.qrcode.innerHTML = ''
      // console.log('生成新二维码!')

      this.makeQrcode()
    }
  }
}
</script>

<style scoped>
.el-main {
  color: #333;
  background-color: #fff;
  padding: 0px;
  font-size: 14px;
  text-align: left;
}

.center {
  margin-bottom: 10px;
}

.qrcode {
  height: 250px;
  width: 250px;
  margin: 0px auto;
}
</style>
