<!-- fromItem 动态调用组件 -->
<template>
  <div>
    <template v-if="!item.noFromItem">
      <el-form-item :label="item.name"
        v-show="type !== 'hidden' && item.position !== 'hidden'"
        :prop="item.data"
        :rules="itemValidate">
        <Content :item="item"
          :dataSource="dataSource"
          :menuType="menuType"
          :actionType="actionType"
          :type="type"
          :readonly="readonly"
          :disabled="disabled"
          :source="source"
          :sourceKey="sourceKey"
          @changeDataInContent="handleChangeData"
          :resetDataTime="resetDataTime"
          v-on="$listeners"></Content>
        <div class="sub-title"
          v-if="item.note">{{item.note}}</div>
      </el-form-item>
    </template>
    <template v-else>
      <Content :item="item"
        :dataSource="dataSource"
        :menuType="menuType"
        :actionType="actionType"
        :type="type"
        :readonly="readonly"
        :disabled="disabled"
        :source="source"
        :sourceKey="sourceKey"
        @changeDataInContent="handleChangeData"
        :resetDataTime="resetDataTime"
        v-on="$listeners"></Content>
    </template>
  </div>
</template>

<script>
import Content from '@/file/components/formItem/content'

export default {
  props: ['item', 'dataSource', 'menuType', 'type', 'readonly', 'disabled', 'source', 'resetDataTime', 'tableScope', 'actionType', 'sourceKey'],

  components: {
    Content
  },

  data() {
    let patternTest = (rule, value, callback) => {
      // console.log(111)
      // this.item.pattern
      let reg = new RegExp(this.item.pattern)
      if (value && !reg.test(value)) { // 由其他规则判断是否允许为空
        callback(new Error('请输入正确格式的' + this.item.name))
      } else {
        callback()
      }
    }
    let regTest = (reg, msg) => {
      return (rule, value, callback) => {
        // let reg = /^\w*$/
        if (value && !reg.test(value)) { // 由其他规则判断是否允许为空
          let mess = msg || '请输入正确内容'
          callback(new Error(mess))
        } else {
          callback()
        }
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (this.dataSource.password && value !== this.dataSource.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      validates: {
        notAllowEmpty: { required: true, message: (this.item.name || '') + '不能为空', trigger: 'change' },
        pattern: { validator: patternTest, trigger: 'blur' },
        // 拓展验证器
        // 可用验证规则： https://github.com/yiminghe/async-validator
        url: { type: 'url', message: '请输入完整正确网址格式，需要携带http或https' },
        // 自定义验证规则
        name: { validator: regTest(/^[a-zA-Z0-9\u4e00-\u9fa5()（）（）\.\_]{0,40}$/, '请输入正确的名称'), trigger: 'blur' },
        code: { validator: regTest(/^[a-zA-Z0-9]{0,10}$/, '编码只能由数字和字母组成，长度10位以内'), trigger: 'blur' },
        reg_name: { max: 40, message: '长度在 40 个字符以内', trigger: 'blur' },
        number: { validator: regTest(/^[0-9]*$/, '请输入正确数值'), trigger: 'blur' },
        repassword: { validator: validatePass2, trigger: 'blur' },
        // password: { min: 6, max: 20, message: '密码长度应在 6 到 20 个字符之间', trigger: 'blur' },
        password: { validator: regTest(/(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,20}$)|(^default$)/, '密码至少包含大写字母，小写字母，数字，且不少于8位。'), trigger: 'blur' }, // 密码强度加强
        email: { type: 'email', message: '请输入正确邮箱格式', trigger: 'blur' }
      }

    }
  },

  computed: {

    itemValidate() {
      let itemValidate = []
      if (this.item.type !== 'hidden') {
        if (this.item.allowEmpty != null && (this.item.allowEmpty === false || this.item.allowEmpty === 'false')) {
          itemValidate.push(this.validates['notAllowEmpty'])
        }

        if (this.item.pattern != null) {
          itemValidate.push(this.validates['pattern'])
        }

        // console.log('自定义验证器',this.item.data,this.item)
        if (this.item.validate != null) {
          if (this.validates[this.item.validate]) {
            // 自定义验证器
            itemValidate.push(this.validates[this.item.validate])
          } else {
            alert('验证器' + this.item.validate + '未开发')
          }
        }

        if (this.item.type === 'email') {
          itemValidate.push(this.validates['email'])
        }
        if (this.item.type === 'repassword') {
          itemValidate.push(this.validates['repassword'])
        }
      }

      return itemValidate
    }
  },

  methods: {
    handleChangeData({ data, field }) {
      let obj = {
        field: field,
        data: data
      }
      if (this.tableScope) {
        obj.tableIndex = this.tableScope.$index
      }
      this.$emit('changeData', obj)
    }
  }

}
</script>
<style scoped>
.el-form-item {
  min-height: 34px;
}
.sub-title {
  font-size: 14px;
  color: #aab7c4;
}
</style>
