import { render, staticRenderFns } from "./tag.vue?vue&type=template&id=ba6652ca&scoped=true"
import script from "./tag.vue?vue&type=script&lang=js"
export * from "./tag.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.11_css-loader@6.11.0_webpack@5.95.0__lodash@4.17.21__jwnxr7kw2qy3fk4ahrxeovtfxy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba6652ca",
  null
  
)

export default component.exports