var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"print_container",style:({
      'font-family': 'Arial, Microsoft Yahei, Helvetica Neue, Helvetica, sans-serif',
      border: '1px solid #efefef',
      fontSize: '3.2mm', // 16
      lineHeight: '4.2mm', // 16
      overflow: 'auto',
      boxSizing: 'border-box',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      height: '54mm', //  1毫米 约等于 3.78像素
      width: '90mm', //  1毫米 约等于 3.78像素
    })},[(_vm.personInfo.corp_logo || _vm.personInfo.bloc_logo)?_c('img',{style:({height: '7.2mm',position:'absolute',top: '3.5mm',left: '4.1mm'}),attrs:{"src":_vm.personInfo.corp_logo || _vm.personInfo.bloc_logo}}):_vm._e(),_c('div',{style:({position:'absolute', top: '50%', marginTop: `-${(8.2)/2}mm`,fontSize: '8.2mm',fontWeight: '',textAlign: 'center',width: '100%'})},[_vm._v(_vm._s(_vm.personInfo.user_name))]),_c('div',{style:({position:'absolute', bottom: '4.5mm', left: '4.1mm',fontSize:'3.2mm',lineHeight: '4.2mm',})},[(_vm.personInfo.corp_name)?_c('div',{staticStyle:{"font-weight":"600","margin-bottom":"0.6mm"}},[_vm._v(_vm._s(_vm.personInfo.corp_name))]):_vm._e(),(_vm.personInfo.tel)?_c('div',[_vm._v("电话："+_vm._s(_vm.personInfo.tel))]):_vm._e(),(_vm.personInfo.tel)?_c('div',[_vm._v("邮箱："+_vm._s(_vm.personInfo.email))]):_vm._e()]),_c('img',{style:({position:'absolute', bottom: '4.5mm', right: '4.1mm',height:'8.4mm'}),attrs:{"src":_vm.personInfo.barCodeUrl}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }