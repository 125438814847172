<template>
  <div>
    <p>{{creator}}发起转单请求，转单原因: {{info.reason || "无"}}</p>
    <p>点击转单后，将转单数据生产新的草稿单，并同步作废原始草稿单及其下游数据</p>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
    creator: String
  }
};
</script>
