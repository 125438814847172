<!-- fromItem 动态调用组件 -->
<template>
  <div :class="{ allFromItem: !!disabled }" :key="String(disabled)">
    <component :is="name" :item="item" :dataSource="dataSource" :source="source" :menuType="menuType"
      :readonly="item.readonly || false" :actionType="actionType" :sourceKey="sourceKey"
      :disabled="disabled || item.readonly || false" :resetDataTime="resetDataTime" :handleChangeData="handleChangeData"
      v-on="$listeners"></component>
  </div>
</template>
<script>
import formText from '@/file/components/formItem/formItems/text'
import formNumber from '@/file/components/formItem/formItems/number'

import formDate from '@/file/components/formItem/formItems/date'
import formDaterange from '@/file/components/formItem/formItems/daterange'
import formEmail from '@/file/components/formItem/formItems/email'
import formFile from '@/file/components/formItem/formItems/file'
import formGallery from '@/file/components/formItem/formItems/gallery'
// import formJsoneditor from '@/file/components/formItem/formItems/jsoneditor'
import formPassword from '@/file/components/formItem/formItems/password'
import formRepassword from '@/file/components/formItem/formItems/repassword'
// import formRichtext from '@/file/components/formItem/formItems/richtext2'
import formTextarea from '@/file/components/formItem/formItems/textarea'
import formHidden from '@/file/components/formItem/formItems/hidden'
import formSelect2 from '@/file/components/formItem/formItems/select2'
import formCheckbox from '@/file/components/formItem/formItems/checkbox'
import formCheckbox2 from '@/file/components/formItem/formItems/checkbox2'

import formRadio from '@/file/components/formItem/formItems/radio'
import formSwitch from '@/file/components/formItem/formItems/switch'
import formTypeahead from '@/file/components/formItem/formItems/typeahead'
import formTag from '@/file/components/formItem/formItems/tag'
import formOneTimeUse from '@/file/components/formItem/formItems/oneTimeUse'
import formTextblur from '@/file/components/formItem/formItems/textblur'
import formDropdown from '@/file/components/formItem/formItems/dropdown'
import formComponent from '@/file/components/formItem/formItems/component'

import formColorselect from '@/file/components/formItem/formItems/colorselect'
import formColorselect2 from '@/file/components/formItem/formItems/colorselect2'
import formPlanOverWgt from '@/file/components/formItem/formItems/planOverWgt'
import formColorselectMulti from '@/file/components/formItem/formItems/colorselectMulti'
import formColorpicker from '@/file/components/formItem/formItems/colorpicker'
import formMixSelector from '@/file/components/formItem/formItems/mixSelector'
import formWeappParams from '@/file/components/formItem/formItems/weappParams'
import formPriceRules from '@/file/components/formItem/formItems/priceRules'
import formCustomerPriceRules from '@/file/components/formItem/formItems/customerPriceRules'

export default {
  props: ['item', 'dataSource', 'menuType', 'type', 'readonly', 'disabled', 'source', 'resetDataTime', 'tableScope', 'actionType', 'sourceKey'],

  components: {
    formText,
    formNumber,
    formDate,
    formDaterange,
    formEmail,
    formFile,
    formGallery,
    formPlanOverWgt,
    // formJsoneditor,
    formPassword,
    formRepassword,
    // formRichtext,
    formTextarea,
    formHidden,
    formSelect2,
    formCheckbox,
    formCheckbox2,
    formRadio,
    formSwitch,
    formTypeahead,
    formTag,
    formTextblur,
    formOneTimeUse,
    formDropdown,
    formComponent,
    formColorselect,
    formColorpicker,
    formMixSelector,
    formColorselect2,
    formColorselectMulti,
    formWeappParams,
    formPriceRules,
    formCustomerPriceRules
  },

  data () {
    return {
    }
  },

  computed: {
    name () {
      let type = this.type

      // 兼容别名、不一致的情况
      // datetime
      if (this.type === 'datetime') {
        type = 'date'
      }
      if (this.type === 'cmtextarea') { // cmtextarea 代码编辑器已弃用
        type = 'jsoneditor'
      }
      return 'form-' + type
    }

  },

  methods: {
    handleChangeData (data, field, tableRow) {
      let obj = {
        field: field || this.item.data,
        data: data
      }
      if (this.tableScope) {
        obj.tableIndex = this.tableScope.$index
      }
      this.$emit('changeDataInContent', obj)
    }
  }

}
</script>
<style>
.allFromItem .is-disabled .el-input__inner,
.allFromItem .is-disabled .el-textarea__inner {
  background-color: #fff !important;
  cursor: default !important;
  border: 0 !important;
  border-bottom: 1px solid #dcdfe6 !important;
  border-radius: inherit;
  color: #606266;
  resize: none;
}

.allFromItem .is-disabled input::-webkit-input-placeholder {
  color: #fff !important;
}

.allFromItem .is-disabled input:-moz-placeholder {
  color: #fff !important;
}

.allFromItem .is-disabled input :-ms-input-placeholder {
  color: #fff !important;
}

.allFromItem .el-input.is-disabled .el-input__icon {
  cursor: default !important;
}

.allFromItem .is-disabled .el-select__caret {
  display: none !important;
}
</style>
