<script>
export default {
  name: 'render-custom-component',
  props: ['item',
    'dataSource',
    'source',
    'menuType',
    'readonly',
    'actionType',
    'sourceKey',
    'disabled',
    'resetDataTime',
    'handleChangeData'],
  render(h) {
    let self = this
    return h(self.item.componentName, {
      props: {
        item: self.item,
        dataSource: self.dataSource,
        source: self.source,
        menuType: self.menuType,
        readonly: self.readonly,
        actionType: self.actionType,
        sourceKey: self.sourceKey,
        disabled: self.disabled,
        resetDataTime: self.resetDataTime,
        handleChangeData: self.handleChangeData,
      }
    })
  }
}
</script>
