import axios from 'axios'
import { getApp } from '@/main.js'
import { http, showWarningModal, toUrl } from '@/utils'
import { MessageBox, Loading } from 'element-ui'
import { ORDERAPI_PREFIX } from './model'

const prefix = 'hiramToken_'
// const testEnv = window.location.origin.includes('master') ? 'master' : 'f1'
// export const BASE_SYSTEM_URL = env === 'ddgold' ? `https://www.ddgold.${suffix}/` : `https://${testEnv}.hiram.cn/`

/**
 * 保存 token 相关信息
 * @param {String} accessToken 必填的
 * @param {Number} expire 必填的，有效期，10位时间戳
 */
export function saveTokenInfo (accessToken, expire) {
  return new Promise((resolve, reject) => {
    try {
      localStorage.setItem(`${prefix}accessToken`, accessToken)
      localStorage.setItem(`${prefix}expire`, expire)
      console.log('更新token信息成功')
      resolve(true)
    } catch (e) {
      reject(e)
    }
  })
}

function getTokenInfo (key) {
  return new Promise((resolve, reject) => {
    try {
      resolve(localStorage.getItem(`${prefix}${key}`) || '')
    } catch (e) {
      reject(e)
    }
  })
}

/**
 * 校验token是否有效
 */
async function verifyToken () {
  const accessToken = await getTokenInfo('accessToken')
  const expire = await getTokenInfo('expire')
  const expireTime = Number(expire)
  if (accessToken && expire && expireTime && expireTime * 1000 > +new Date()) {
    return true
  }
  console.log('token失效，有效时间为：', new Date(expireTime * 1000))
  return false
}

async function refreshToken () {
  try {
    let res
    // 开发环境，手动填写最新的 token
    if (process.env.NODE_ENV !== 'production') {
      // throw new Error('去config.json填写最新的token')
      // https://f1.hiram.cn/user/getaccesstoken 获取
      let { value } = await MessageBox.prompt('填写最新的token', '填写最新token', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })

      if (!value) {
        res = await apiHttp({ // 开发环境自动登录
          url: 'http://127.0.0.7:805/user/testlogin',
          method: 'get'
        }, false, true)
        value = res.accessToken + ' ' + res.expire
      }

      let [token, expire] = value.split(' ')
      saveTokenInfo(token, expire)

    } else {
      // let refreshUrl = BASE_SYSTEM_URL + 'user/getaccesstoken'
      let refreshUrl = '/user/getaccesstoken?v=' + (+new Date())
      // 使用订单系统的 http \ toUrl 访问订单系统的 api
      res = await http({
        url: toUrl(refreshUrl),
        method: 'get'
      })

      if (!res.accessToken || !res.expire) {
        throw new Error('accessToken过期，且获取不到，请刷新或重新登录')
      }
      saveTokenInfo(res.accessToken, res.expire)
    }
  } catch (e) {
    throw e
  }
}

export async function getToken () {
  try {
    const status = await verifyToken()
    if (!status) {
      await refreshToken()
    }
    const accessToken = await getTokenInfo('accessToken')
    return accessToken
  } catch (e) {
    showWarningModal(e)
  }
}

export function _axios (args) {
  return new Promise((resolve, reject) => {
    let loading = null

    if (args.noLogin !== true) {
      loading = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    }

    axios(args).then(res => {
      if (args.noLogin !== true) {
        loading.close()
      }
      let status = res.status + ''
      if (status.startsWith('4') || status.startsWith('5')) {
        let err = {
          message: res.message || '请求错误',
          status
        }
        // showWarningModal(err)
        reject(err)
      } else {
        if (res.data && !res.data.result) {
          let message = res.data.msg || '请求错误'
          if (typeof message === 'object') {
            message = Object.values(message).join('、')
          }
          let err = {
            message,
            status
          }
          // showWarningModal(err)
          reject(err)
        }
        resolve(res.data)
      }
    }, (err) => {
      if (args.noLogin !== true) {
        loading.close()
      }
      reject(err)
    })
  })
}

export function _axiosGetAllResult (args) {
  return new Promise((resolve, reject) => {
    let loading = null

    if (args.noLogin !== true) {
      loading = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    }

    axios(args).then(res => {
      if (args.noLogin !== true) {
        loading.close()
      }
      let status = res.status + ''
      if (status.startsWith('4') || status.startsWith('5')) {
        let err = {
          message: res.message || '请求错误',
          status
        }
        // showWarningModal(err)
        reject(err)
      } else {
        resolve(res.data)
      }
    }, (err) => {
      if (args.noLogin !== true) {
        loading.close()
      }
      reject(err)
    })
  })
}

/**
 * 获取 api 信息
 * @param {*} options axios的选项
 * @param {boolean} second 二次请求
 * @param {boolean} noLogin 不使用Authorization
 * @returns 
 */
export async function apiHttp (options, second = false, noLogin = false) {
  try {
    // 设置 header
    if (!noLogin) {
      // 设置 header
      const token = await getToken()
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
    // 指定 url 前缀，有config拿config
    let urlPrefix
    let app = getApp()
    if (app && app.store && app.store.state.config.orderApiURL) {
      urlPrefix = app.store.state.config.orderApiURL
    } else {
      urlPrefix = ORDERAPI_PREFIX
    }

    // 前缀加 /
    if (!urlPrefix.endsWith('/')) {
      urlPrefix += '/'
    }

    !options.url.startsWith('http') && (options.url = urlPrefix + options.url)

    // 本地，去除档案模块旧本地地址的 .json
    if (process.env.NODE_ENV !== 'production' && options.url.includes('.json')) {
      options.url = options.url.replace('.json', '')
    }

    const res = await _axios(options)
    return res
  } catch (e) {
    if ((e.status === '401' || e.message === '未授权，请重新登录') && !second) {
      // 清除token，并重新请求
      saveTokenInfo('', 0)
      return apiHttp(options, true)
    } else {
      showWarningModal(e.message)
      throw e
    }
  }
}

// 获取 api 信息
export async function apiHttpGetAllResult (options, second = false) {
  try {
    // 设置 header
    const token = await getToken()
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    // 指定 url 前缀，有config拿config
    let urlPrefix
    let app = getApp()
    if (app && app.store && app.store.state.config.orderApiURL) {
      urlPrefix = app.store.state.config.orderApiURL
    } else {
      urlPrefix = ORDERAPI_PREFIX
    }

    // 前缀加 /
    if (!urlPrefix.endsWith('/')) {
      urlPrefix += '/'
    }

    !options.url.startsWith('http') && (options.url = urlPrefix + options.url)

    // 本地，去除档案模块旧本地地址的 .json
    if (process.env.NODE_ENV !== 'production' && options.url.includes('.json')) {
      options.url = options.url.replace('.json', '')
    }

    const res = await _axiosGetAllResult(options)
    return res
  } catch (e) {
    if ((e.status === '401' || e.message === '未授权，请重新登录') && !second) {
      // 清除token，并重新请求
      saveTokenInfo('', 0)
      return apiHttpGetAllResult(options, true)
    } else {
      showWarningModal(e.message)
      throw e
    }
  }
}
