import _ from 'lodash'
import store from '@/store/store'
import { isStrJSON, combineUrl } from '~utils'
import { apiHttp } from '~utils/query/token'

const apiList = {
  deliveryDateChanged: {
    url: 'order/orderverify/batdeliveryatconfirm',
    method: 'post'
  },
  abolishStatusChanged: {
    url: 'order/orderverify/abolishconfirm',
    method: 'post'
  },
  unabolishStatusChanged: {
    url: 'messagelist/unabolishstatuschanged',
    method: 'get'
  },
  returnOrder: {
    url: 'order/saleorder/returnorderconfirm',
    method: 'post'
  },
  saleOrderStatusChanged: {
    url: 'order/saleorder/deleteorders',
    method: 'get'
  }
}

class noticeCenterApi {
  /**
   * 获取消息列表
   */
  static async getMessageList (options) {
    const $store = store()
    const getMessageListApi = { ...$store.state.config.noticeCenter.getMessageList, noLogin: true }
    options = this.combineOptions(getMessageListApi, options)
    const result = await this.uniteRequest(options)
    if (result && result.result) {
      const messageList = result.data // 对象 引用类型
      for (let key in messageList) {
        if (isStrJSON(messageList[key].data)) {
          messageList[key].data = JSON.parse(messageList[key].data)
        }
      }
    }
    return result
  }

  /**
   * 更新消息状态
   * @param {Object} data
   */
  static async updateMessageStatus (data) {
    const $store = store()
    const updateMessageStatusApi = $store.state.config.noticeCenter.updateMessageStatus

    const options = this.combineOptions(updateMessageStatusApi, data)
    const result = await this.uniteRequest(options)
    return result
  }

  /**
   * 交货日期变更 消息确认
   * @param {Object} data
   */
  static async deliveryDateChanged (data) {
    const options = this.combineOptions(apiList.deliveryDateChanged, data)
    const result = await this.uniteRequest(options)
    return result
  }

  /**
   * 作废状态变更 消息确认
   * @param {Object} data
   */
  static async abolishStatusChanged (data) {
    const options = this.combineOptions(apiList.abolishStatusChanged, data)
    const result = await this.uniteRequest(options)
    return result
  }

  static async unabolishStatusChanged (data) {
    const options = this.combineOptions(apiList.unabolishStatusChanged, data)
    const result = await this.uniteRequest(options)
    return result
  }



  /**
   * 销售订单状态变更 消息确认
   * @param {Object} data 
   * @returns 
   */
  static async saleOrderStatusChanged (data) {
    const options = this.combineOptions(apiList.saleOrderStatusChanged, data)
    const result = await this.uniteRequest(options)
    return result
  }

  /**
   * 转单变更 消息确认
   * @param {Object} data
   */
  static async returnOrder (data) {
    const options = this.combineOptions(apiList.returnOrder, data)
    const result = await this.uniteRequest(options)
    return result
  }

  /**
   * 组装options
   * @param {Object} api
   * @param {Object} data
   */
  static combineOptions (api, data = {}) {
    const $store = store()
    const options = {}
    if (api.method === 'get') {
      options.url = combineUrl(api.url, data)
    } else if (api.method === 'post') {
      options.url = api.url
      options.data = _.cloneDeep(data)
      if (!options.data.filter) {
        options.data.filter = {}
      }
      options.data.filter.verifyCorpId = $store.state.config.user.corp.corp_id// 增加公司ID过滤条件
    }
    options.method = api.method
    options.noLogin = api.noLogin
    return options
  }

  /**
   * 在这里进行统一的api请求 并处理异常
   * @param {Object} options
   */
  static async uniteRequest (options) {
    try {
      const result = await apiHttp(options)
      return result
    } catch (err) {
      console.error(err)
      return null
    }
  }
}

export default noticeCenterApi
