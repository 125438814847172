
<template>
  <div>
    <el-button size="mini" @click="openSetting">
      <i class="el-icon-setting"></i> 打开设置窗口
    </el-button>

    <el-dialog
      title="设置"
      :visible.sync="open"
      width="80%"
      v-if="open"
      append-to-body
    >
      <div v-for="item in source" :key="item.stockcate_id" class="grid">
        <div class="text-align-right padding-right-10">
          {{ item.stockcate_name }}
        </div>
        <el-input placeholder="请输入超单率" v-model="values[item.stockcate_id]"
          ><template slot="append">%</template></el-input
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="open = false">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiHttp } from '../../../../utils/query/token';
export default {
  props: [
    "item",
    "dataSource",
    "readonly",
    "disabled",
    "itemValidate",
    "handleChangeData"
  ],
  data () {
    return {
      open: false,
      source: [],
      values: {}
    }
  },
  async mounted () {
    let res = await apiHttp({
      url: `files/stockcate/index?filter[is_seal]=0&filter[dr]=0&page=1&per-page=500&sort=lft`,
      method: 'get'
    })
    this.source = res.data || []
  },
  methods: {
    openSetting () {
      this.values = JSON.parse(this.dataSource[this.item.data] || '{}')
      this.open = true;
    },
    sure () {
      this.handleChangeData(JSON.stringify(this.values));
      this.open = false;
    }
  }
};
</script>
<style scoped>
.grid {
  display: grid;
  grid-template-columns: 20% 80%;
  padding: 5px;
  line-height: 1.5;
}

.grid:hover {
  background: #eee;
}
</style>
