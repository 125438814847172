<!-- 文本框 -->
<template>

    <img-upload :uploadType="3"
      :fileList="dataSource[item.data]"
      :editing="!(readonly || disabled)"
      :params="params"
      @changeImage="handleChangeImage"></img-upload>

</template>

<script>
import imgUpload from './components/imgUpload'
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'itemValidate', 'handleChangeData'],
  data () {
    return {
      tempFiles: [],
      // 目前只有公司、集团档案，以下值都是 undefined
      params: {
        action: undefined,
        images_source_id: undefined,
        state: undefined
      }
    }
  },
  components: {
    imgUpload
  },
  methods: {
    handleChangeImage (data) {
      // this.handleChangeData(data)
      // :uploadType="3"  单文件url
      if (data[0]) {
        this.handleChangeData(data[0].thumbSrc)
      } else {
        this.handleChangeData('')
      }
    }
  },
  mounted () {
    console.log(this.dataSource[this.item.data])
  }
}

</script>

<style scoped>
</style>
