<template>
  <div>
    <p>【{{ info.saleOrderCode }}】已被取消下单</p>
    <p>点击“同步取消单据”后，将删除所有关联单据数据</p>
  </div>
</template>

<script>
export default {
  props: {
    info: Object
  }
}
</script>
