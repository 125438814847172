var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"print_container",style:({
      'font-family': 'Arial, Microsoft Yahei, Helvetica Neue, Helvetica, sans-serif',
      padding: '3mm', // 20
      border: '1px solid #efefef',
      fontSize: '3.2mm', // 16
      lineHeight: '4.2mm', // 16
      overflow: 'auto',
      boxSizing: 'border-box',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      height: '45mm', //  1毫米 约等于 3.78像素
      width: '90mm', //  1毫米 约等于 3.78像素
    })},[_c('div',{style:({float:'left'})},[_c('div',{style:({marginBottom: '1mm'})},[_vm._v(_vm._s(_vm.personInfo.user_name)+" 专属条码： ")]),_c('img',{style:({height:'10mm'}),attrs:{"src":_vm.personInfo.barCodeUrl}})]),_c('div',{style:({float:'left',marginLeft: '2mm', paddingLeft: '2mm', borderLeft: '0.2mm solid #eee'})},[(_vm.personInfo.corp_logo || _vm.personInfo.bloc_logo)?_c('img',{style:({height: '7.2mm'}),attrs:{"src":_vm.personInfo.corp_logo || _vm.personInfo.bloc_logo}}):_vm._e(),_c('div',{style:({fontSize: '4mm', margin: '1mm 0mm 1mm'})},[_vm._v(_vm._s(_vm.personInfo.user_name))]),(_vm.personInfo.tel)?_c('div',[_vm._v(_vm._s(_vm.personInfo.tel))]):_vm._e(),(_vm.personInfo.corp_name)?_c('div',[_vm._v(_vm._s(_vm.personInfo.corp_name))]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }