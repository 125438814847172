import utils from '@/utils'
import axios from 'axios'

export default {

  getMenuConfig ({ state, commit }, menuType) {
    return new Promise((resolve, reject) => {
      if (!state.menu[menuType]) {
        reject(new Error('不存在该列表菜单' + menuType))
        return
      }
      let menuId = state.menu[menuType].menuId
      let url = process.env.NODE_ENV === 'production' ? state.config.getMenuConfig : state.config.getMenuConfig + '-' + menuType + '.json'
      utils.http({
        //
        url: url + '?menuId=' + menuId,
        method: 'get',
        // data: {
        //   menuId: menuId
        // },
        noErrorDialog: true
      }).then(data => {
        commit('setMenuConfig', {menuType: menuType, data: data})
        resolve(data)
      }, (err) => {
        reject(err)
      })
    })
  },


  getSource ({ state, commit, getters }, menuType) {
    // 看下是否需要source
    if (!getters.needSource[menuType]) {
      return
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('getSource')
    }
    return new Promise((resolve, reject) => {
      if (!state.menu[menuType]) {
        reject(new Error('不存在该列表菜单' + menuType))
        return
      }
      let menuId = state.menu[menuType].menuId
      let getSourceUrl = state.config.getSource ? state.config.getSource : state.config.getMenuConfig
      let url = process.env.NODE_ENV === 'production' ? getSourceUrl : getSourceUrl + '-' + menuType + '.json'
      utils.http({
        //
        url: url + '?menuId=' + menuId,
        method: 'get',
        // data: {
        //   menuId: menuId
        // },
        noErrorDialog: true
      }).then(data => {
        commit('editSource', {menuType: menuType, data: data})
        resolve(data)
      }, (err) => {
        reject(err)
      })
    })
  },


  reloadOwnTree ({ state, commit }, { menuType, showseal, showcode, type }) {
    return new Promise((resolve, reject) => {
      let postData = {
        // showseal: showseal,
        // showcode: showcode,
        // 显示编码、显示封存不请求后台
        showseal: true, // 显示封存，获取全部数据
        showcode: false, // text 不待编码
        menuId: state.menu[menuType].menuId,
        _tabId: state.menu[menuType].menuId
      }
      if (type) {
        postData.type = type
      }
      // console.log('state.config',state.config);

      utils
        .http({
          url: state.config.urlPrefix + (state.menu[menuType].treeKey || state.menu[menuType].primaryKey) + '/list' + (process.env.NODE_ENV === 'production' ? '' : '.json'),
          data: postData
        })
        .then(
          data => {
            commit('setTreeList', { data: data, menuType: menuType })
            resolve(data)
          },
          err => {
            reject(err)
          }
        )
    })
  },
  loadTypelistTree ({ state, commit }, { menuType }) {
    let postData = {
      menuId: state.menu[menuType].menuId,
      _tabId: state.menu[menuType].menuId
    }
    return new Promise((resolve, reject) => {
      utils
        .http({
          url: state.config.urlPrefix + (state.menu[menuType].treeKey || state.menu[menuType].primaryKey) + '/typelist' + (process.env.NODE_ENV === 'production' ? '' : '.json'),
          data: postData
        })
        .then(
          data => {
            resolve(data.treeView)
          },
          err => {
            reject(err)
          }
        )
    })
  },
  getTableData ({ state, commit }, { menuType, paginationOptions, selectedId, typelistType }) {
    return new Promise((resolve, reject) => {
      let postData = {
        selectId: selectedId,
        paginationOptions: paginationOptions,
        menuId: state.menu[menuType].menuId,
        _tabId: state.menu[menuType].menuId
      }
      if (state.menu[menuType].style === 'typelist-table') {
        postData.type = typelistType
        if (!selectedId) {
          postData.type = ''
        }
      }
      utils
        .http({
          url: state.config.urlPrefix + state.menu[menuType].primaryKey + '/table' + (process.env.NODE_ENV === 'production' ? '' : '.json'),
          data: postData
        })
        .then(
          data => {
            commit('setTableList', { data: data, menuType: menuType })
            resolve()
          },
          err => {
            reject(err)
          }
        )
    })
  },
  removeFormData ({ state, commit }, { url, id, menuType }) {
    let postData = {
      id: id,
      menuId: state.menu[menuType].menuId,
      _tabId: state.menu[menuType].menuId,
      selectId: state.selectedId[menuType]
    }
    // postData 特殊处理 目前只有removeData、editFormData方法，只有group这个菜单，如果以后多了封装起来处理
    if(menuType === 'group'){
      postData.id = {
        name: state.formData[menuType].name,
        bloc_id:  state.formData[menuType].bloc_id,
      }
    }
    return new Promise((resolve, reject) => {
      utils
        .http({
          // url: state.menu[menuType].listUrl + '/del' + (process.env.NODE_ENV === 'production' ? '' : '.json'),
          // url: process.env.NODE_ENV === 'production' ? url : state.config.urlPrefix + state.menu[menuType].primaryKey + '/del.json',
          url: state.config.urlPrefix + state.menu[menuType].primaryKey + '/del' + (process.env.NODE_ENV === 'production' ? '' : '.json'),
          data: postData
        })
        .then(
          data => {
            // commit('setTreeList', { data: data, menuType: menuType })
            resolve(data)
          },
          err => {
            reject(err)
          }
        )
    })
  },
  moveFormData ({ state, commit }, { url, action, id, menuType }) {
    let postData = {
      id: id,
      selectId: id,
      action: action,
      menuId: state.menu[menuType].menuId,
      _tabId: state.menu[menuType].menuId
    }
    return new Promise((resolve, reject) => {
      utils
        .http({
          url: state.config.urlPrefix + state.menu[menuType].primaryKey + '/move' + (process.env.NODE_ENV === 'production' ? '' : '.json'),
          data: postData
        })
        .then(
          data => {
            resolve(data)
          },
          err => {
            reject(err)
          }
        )
    })
  },

  editFormData ({ state, commit }, { url, method, menuType, data }) {
    console.log(state.formData[menuType], state.menu[menuType]['primaryKey'] + '_id')

    let formData = data
    let idField = state.menu[menuType]['primaryKey'] + '_id'
    if (idField.startsWith('corp-')) {
      idField = idField.replace('corp-', '')
    }
    let postData = {
      id: method === 'add' ? {} : (state.formData[menuType].id || state.formData[menuType][idField]),
      menuId: state.menu[menuType].menuId,
      _tabId: state.menu[menuType].menuId,
      data: formData,
      selectId: state.selectedId[menuType]
    }
    let postUrl
    if (url) {
      console.log('actionUrl')
      postUrl = process.env.NODE_ENV === 'production' ? url : state.config.urlPrefix + state.menu[menuType].primaryKey + '/save.json'
    } else {
      postUrl = state.config.urlPrefix + state.menu[menuType].primaryKey + '/save' + (process.env.NODE_ENV === 'production' ? '' : '.json')
    }
    // postData 特殊处理 目前只有removeData、editFormData方法，只有group这个菜单，如果以后多了封装起来处理
    if(menuType === 'group'){
      if(method !== 'add'){
        postData.id = {
          name: state.formData[menuType].name,
          bloc_id:  state.formData[menuType].bloc_id,
        }
      }
    }
    return new Promise((resolve, reject) => {
      utils
        .http({
          url: postUrl,
          data: postData
        })
        .then(
          data => {
            commit('setFormData', { data: data.data, menuType: menuType })
            resolve()
          },
          err => {
            reject(err)
          }
        )
    })
  },

  setEmptyFormData ({ state, commit }, menuType) {
    return new Promise((resolve, reject) => {
      let data = {}

      let items = state.formStyle[menuType]
      if (items) {
        for (let i = 0; i < items.length; i++) {
          const item = items[i]

          // 根据类型分配格式
          if (['gallery', 'file', 'checkbox2'].includes(item.type)) {
          // 数组格式
            data[item.data] = []
          } else if (item.type === 'checkbox') {
          // checkbox 开关
            data[item.data] = '0'
          } else if (item.type === 'jsoneditor' || item.type === 'cmtextarea') {
          // json编辑器
            data[item.data] = '{}'
          } else if (item.type === 'number') {
          // json编辑器
            data[item.data] = 0
          } else if (item.type === 'richtext') {
          // 特定格式富文本
            data[item.data] = '{"text":"","image":{}}'
          } else if (item.type === 'daterange') {
          // 时间区间
            data[item.dataSchema.start] = ''
            data[item.dataSchema.end] = ''
          } else if (item.type === 'switch') {
          // 开关，默认关闭
            data[item.data] = item.dataSchema.off
          } else {
            data[item.data] = undefined
          }
        }
      }
      // console.log(data, 'datadatadata')

      commit('setFormData', { data: data, menuType: menuType })
      resolve()
    })
  }

}


