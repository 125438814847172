<template>
  <div :class="{
    'sidebar-outer': true,
    'height-100-percent': true,
    hover: hover,
    hiddenMenu: hiddenMenu,
  }">
    <div class="
        sidebar-container
        container
        height-100-percent
        flex
        side
        flex-column flex-space-between
      " @mousemove.stop="handleMouseover" @mouseout.stop="handleMouseout">
      <!-- <div class="tac"><img src="./../assets/images/default.png" style="width:56px;"></div> -->
      <div>
        <!-- 路由生成导航 -->
        <el-menu :collapse="true" class="padding-top-20 no-border" text-color="#ffffff" active-text-color="#f2f2f2">
          <template v-for="(item, key) in menu">
            <!-- 有子级 -->
            <template v-if="item.nodes">
              <el-submenu :index="item.menuName" v-if="iconShow(item)" popper-class="side-submenu"
                :popper-append-to-body="false" :key="key">
                <template slot="title">
                  <i :class="item.icon || 'el-icon-menu'"></i>
                  <span>{{ item.menuName }}</span>
                  <div class="diy-menu-name">{{ item.menuName }}</div>
                </template>
                <template v-for="(secondItem, secondKey) in item.nodes">
                  <!-- 有子元素 -->
                  <template v-if="secondItem.nodes">
                    <el-menu-item-group :key="secondKey" v-if="secondItem.nodes && secondItem.nodes.length">
                      <template slot="title">{{
    secondItem.menuName
  }}</template>
                      <menu-item v-for="(thirdItem, thirdKey) in secondItem.nodes" :item="thirdItem"
                        :key="thirdKey"></menu-item>
                    </el-menu-item-group>
                  </template>
                </template>
              </el-submenu>
            </template>

            <template v-else>
              <menu-item :key="item.menuName + item.type" :item="item" top isSidebar></menu-item>
            </template>
          </template>
        </el-menu>
      </div>

      <!-- 非生成导航 -->
      <div>
        <el-menu :collapse="true" class="padding-bottom-20 no-border" background-color="#409eff" text-color="#ffffff"
          active-text-color="#f2f2f2">
          <el-menu-item index="2" v-if="inNode" @click="handleDeviceSetting">
            <i class="fa-window-restore"></i>
            <span slot="title">调用设备</span>
            <div class="diy-menu-name">调用设备</div>
          </el-menu-item>

          <el-menu-item index="3" @click="goToAppCenter">
            <i class="fa-android"></i>
            <span slot="title">应用中心</span>
            <div class="diy-menu-name">应用中心</div>
          </el-menu-item>
          <!-- <el-menu-item index="3"
            @click="handleOpenOrder"
            v-if="$store.state.config.user.corp.corp_id">
            <i class="fa-file-text-o"></i>
            <span slot="title">新建订单快捷入口</span>
            <div class="diy-menu-name">新建订单快捷入口</div>
          </el-menu-item>-->

          <!-- <el-menu-item index="4"
            @click="handleToolsToggle">
            <i class="el-icon-more"></i>
            <span slot="title">小工具</span>
            <div class="diy-menu-name">小工具</div>
          </el-menu-item>-->

          <menu-item v-if="$store.state.config.user.corp.corp_id" :item="$store.state.components.param" top
            isSidebar></menu-item>
          <!-- <el-menu-item index="5"
            @click="handleBlog">
            <i class="el-icon-date"></i>
            <span slot="title">更新日志</span>
            <div class="diy-menu-name">更新日志</div>
          </el-menu-item>-->
          <el-submenu popper-class="side-submenu" :popper-append-to-body="true" index="5">
            <template slot="title">
              <i class="fa-quote-left"></i>
              <span>意见与反馈</span>
              <div class="diy-menu-name">意见与反馈</div>
            </template>
            <el-menu-item-group>
              <span slot="title">意见与反馈</span>
              <el-menu-item index="1" @click="handleNewFeatures">
                <span slot="title">新功能</span>
              </el-menu-item>
              <el-menu-item index="2" @click="handleFeedback">
                <span slot="title">意见反馈</span>
              </el-menu-item>
              <el-menu-item index="3" v-if="customerService.length" @click="ContactQQCustomerService">
                <span slot="title">客服</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <!-- <el-menu-item index="5" v-if="customerService.length"
            @click="ContactQQCustomerService">
            <i class="fa-qq"></i>
            <span slot="title">反馈</span>
            <div class="diy-menu-name">反馈</div>
          </el-menu-item>-->

          <el-menu-item index="6" @click="handleAboutMe">
            <i class="el-icon-question"></i>
            <span slot="title">关于我们</span>
            <div class="diy-menu-name">关于我们</div>
          </el-menu-item>
        </el-menu>
        <!-- 绝对定位的内容，不能放在外面，会影响flex -->
      </div>
    </div>

    <Tools ref="Tools"></Tools>

    <dialogBox title="选择客服" top="40px" width="80vw" ref="QQCustomerServiceDialog" :append-to-body="true" destory>
      <CustomerService :nodes="customerService"></CustomerService>
    </dialogBox>

    <dialogBox title="关于我们" top="40px" width="490px" ref="aboutMeDialog" :append-to-body="true" destory>
      <aboutMe></aboutMe>
    </dialogBox>

    <dialogBox title="意见反馈" top="40px" width="490px" ref="feedbackDialog" :append-to-body="true" destory>
      <feedBack></feedBack>
    </dialogBox>
  </div>
</template>

<script>
import Tools from './components/tools.vue'
import menuItem from './components/menuItem'
import _ from 'lodash'
import { toUrl, toImg, windowOpenUrl, showConfirmModal } from "@/utils"
import dialogBox from '@/file/components/dialogBox/dialogBox'
import aboutMe from './components/aboutMe'
import feedBack from './components/feedBack'
import CustomerService from './components/CustomerService'
import { inNode } from "@/utils/env"

export default {
  components: {
    menuItem,
    Tools,
    dialogBox,
    feedBack,
    aboutMe,
    CustomerService
  },
  data () {
    return {
      // menu: [] // 根据到时与后台约定的config数据，转为这种格式。
      settingItem: {
        menuName: "参数设置",
        icon: "el-icon-setting",
        type: "component",
        component: "param"
      },
      inNode: inNode,
      menuOpening: false,
      hover: false,
      timer: null,
      hiddenMenu: true, // 隐藏后1秒真正隐藏文字
      oldVisionDialog: false,// 返回旧版对话框
      totalTime: 3, //返回旧版对话框倒计时
      sureContent: '确 定',
      canClick: true
    }
  },
  methods: {
    // formatMenu() {
    //   // todo 按后台格式，进行格式化
    //   this.menu = _.cloneDeep(this.$store.state.config.user.menu)
    // }
    // 是否显示图标；item：有子元素的一个一级菜单
    iconShow (item) {
      for (let i = 0; i < item.nodes.length; i++) {
        const subItem = item.nodes[i]
        if (subItem.nodes && Array.isArray(subItem.nodes) && subItem.nodes.length > 0) {
          // console.log(subItem);

          return true
        }
      }
      return false
    },
    handleToolsToggle () {
      // this.$refs.Tools.show = !this.$refs.Tools.show
      if (!this.$refs.Tools.tools || this.$refs.Tools.tools.length === 0) {
        this.$message.error('当前公司或集团无小工具')
        return
      }
      this.$refs.Tools.userShow = !this.$refs.Tools.userShow
      window.localStorage["toolsUserShow"] = this.$refs.Tools.userShow
    },
    // handleOpenOrder() {
    //   // windowOpenUrl(toUrl('/order/index.html?t=production#/productionOrder/normal'))
    //   windowOpenUrl(toUrl('/index.php#/app/order'))
    // },
    handleAboutMe () {
      this.$refs.aboutMeDialog.show()
    },
    ContactQQCustomerService () {
      this.customerService.forEach(item => {
        if (item.images) {
          item.src = toImg({
            item: {
              md5: item.images,
              h: 100
            },
            imgUrlPrefix: this.$store.state.config.imgUrlPrefix
          })
        }
      })

      this.$refs.QQCustomerServiceDialog.show()
    },
    handleDeviceSetting () {
      window.ipcRenderer.send('deviceSetting')
    },
    handleMouseover () {
      this.hiddenMenu = false
      this.hover = true
    },
    handleMouseout () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.hiddenMenu = true
      }, 500)
      this.hover = false
    },
    goToAppCenter () {
      window.open(toUrl('/appCenter/'))
    },
    handleNewFeatures () {
      this.$router.push({ name: 'newFeatures' })
    },
    handleFeedback () {
      this.$refs.feedbackDialog.show()
    }

    // handleBlog () {
    //   window.open(toUrl('/blog/index.html'))
    // }
  },
  computed: {
    menu () {
      return this.$store.state.config.menu
    },
    customerService () {
      if (Array.isArray(this.$store.state.config.customerService) && this.$store.state.config.customerService.length > 0) {
        return this.$store.state.config.customerService
      } else {
        return []
      }
    },
    // isShowAppCenter () {
    //   if (location.host.includes('ddgold.cn')) {
    //     return false
    //   }
    //   return true
    // },

  },
  mounted () {
  }
}
</script>

<style lang="scss" scope>
.side,
.side .el-menu {
  background-color: #409eff;
}

.side .el-menu i {
  color: #fff;
}

.el-menu-item-group__title {
  color: #fff;
}

.el-menu--vertical.side-submenu {
  display: block;
}

.side-submenu .el-menu--popup {
  background-color: #fff !important;
  z-index: 1055;
  border-left: 1px solid #ddd;
  padding: 30px 0 !important;
  overflow: hidden;
  display: table;
  background-color: #fff;
  white-space: nowrap;
  z-index: 1055;
  border: 1px solid #ddd !important;

  .el-menu-item {
    background-color: #fff !important;
    color: #415161 !important;
    padding: 10px 20px !important;
    height: auto !important;
    line-height: 1em;

    &:hover {
      color: #409eff !important;
    }
  }

  .el-menu-item-group {
    display: table-cell;
    height: 100%;
    border-right: 1px solid #eef1f9;
    font-size: 13px;

    .el-menu-item-group__title {
      color: #96a6b6 !important;
      padding: 0 40px !important;
      line-height: 30px !important;
      height: 30px !important;
      vertical-align: middle !important;
    }

    ul {
      li {
        background-color: #fff !important;
        color: #415161 !important;
        padding: 10px 40px !important;
        height: auto !important;
        line-height: 1em !important;

        &:hover {
          color: #409eff !important;
        }
      }
    }
  }
}

.side-submenu .el-menu--collapse>.el-menu-item [class^="fa-"],
.side-submenu .el-menu--collapse>.el-submenu>.el-submenu__title [class^="fa-"] {
  margin: 0;
  vertical-align: middle;
  width: 24px;
  text-align: center;
}

[class^="fa-"] {
  vertical-align: middle;
  // margin-right: 5px;
  width: 24px;
  text-align: center;
}

.side-submenu .el-menu i {
  color: #fff;
}

[class*=" fa-"],
[class^="fa-"] {
  font: normal normal normal 14px/1 FontAwesome;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: baseline;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.side-submenu .el-menu-item .el-tooltip i,
.side-submenu .el-submenu .el-submenu__title i {
  color: #fff;
}

.side .el-menu-item,
.el-submenu__title {
  height: 42px !important;
  line-height: 42px !important;

  &:hover {
    background: #337ecc;
  }
}

/* 菜单优化 伸展 begin */

.sidebar-outer .sidebar-container {
  width: 64px;
  transition: all 0.3s;
  z-index: 1001;
  position: relative;
}

.sidebar-outer .el-menu {
  width: 64px;
  transition: all 0.3s;
}

.sidebar-outer .diy-menu-name {
  opacity: 0;
  display: inline-block;
  transition: all 0.3s;
}

.sidebar-outer.hiddenMenu .diy-menu-name {
  display: none;
}

.sidebar-outer.hover .sidebar-container {
  width: 185px;
}

.sidebar-outer.hover .el-menu {
  width: 185px;
}

.sidebar-outer.hover .diy-menu-name {
  display: inline-block;
  opacity: 1;
}
</style>

<style>
.el-tooltip__popper {
  display: none !important;
}

#goOldVision .el-dialog__body {
  padding: 20px;
}

#goOldVision .el-dialog__footer {
  padding: 0px 20px 20px;
}

#goOldVision .el-dialog {
  border-radius: 4px;
  margin-top: 30vh !important;
}
</style>
