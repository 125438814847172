<template>
  <el-select v-model="selectedValue"
    :clearable="item.allowEmpty !== false"
    filterable
    :readonly="readonly"
    :disabled="disabled || readonly"
    @change="handleSelected"
    :placeholder="'请选择' + item.name">
    <el-option v-for="item in options"
      :key="item.id"
      :label="item.text"
      :value="sourceKey ? item[sourceKey] : item.id">
    </el-option>
  </el-select>
</template>

<script>
import utils from '@/utils'
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'source', 'handleChangeData', 'itemValidate', 'resetDataTime', 'sourceKey'],
  data () {
    return {
      selectedValue: '',
      options: [],
      list: [] // 存储拿到的数据
    }
  },

  computed: {
  },

  methods: {
    handleSelected () {
      let data
      if(this.sourceKey){
        data = this.selectedValue
      } else {
        let id = this.selectedValue
        data = this.list.filter(item => {
          return item.id === id
        })[0]
        // 后台要求是传特定格式才能改
        if (!data) {
          data = {
            id: null,
            text: null,
            value: null
          }
        }
      }
      this.handleChangeData(data)
    },
    resetData () {

      if(this.sourceKey){
        this.selectedValue = this.dataSource[this.item.data]
      } else {
        // 转换data格式
        if (typeof this.dataSource[this.item.data] === 'string') {
          this.dataSource[this.item.data] = this.list.filter(item => {
            return item.id === this.dataSource[this.item.data]
          })[0]
        }
        this.selectedValue = this.dataSource[this.item.data] ? this.dataSource[this.item.data].id : ''
      }
    }
  },
  mounted () {
    // 远程拿数据
    let url = this.source[this.item.source].dataSourceUrl
    utils.http({
      url: url
    }).then(res => {
      this.list = res.data

      this.options = this.list

      this.resetData()
    })
  },
  watch: {
    resetDataTime () {
      this.resetData()
    }
  }
}

</script>
<style scoped>
</style>
