<!-- 文本框 -->
<template>
  <el-input :item="item"
    type="textarea"
    :rows="item.rows || 4"
    :dataSource="dataSource"
    :readonly="readonly"
    :disabled="disabled"
    v-model="dataSource[item.data]">
  </el-input>
</template>

<script>
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'itemValidate'],
  data () {
    return {
    }
  },

  computed: {
  },

  methods: {}
}

</script>
<style scoped>
</style>
