<template>
  <div class="side-bar ng-scope">
    <div href="javascript:;"
      class="icon-chat"
      title="微信公众号">
      <i class="fa fa-wechat"></i>
      <div class="chat-tips">
        <i></i>
        <!-- <img id="wxImg"
          style="width:138px;height:138px;"
          title="关注微信号"
          :src="wxImg"> -->
          <qrCode></qrCode>
        <h5>要登录请关注微信</h5>
      </div>
    </div>
  </div>
</template>

<script>
import qrCode from '@/components/qrCode/qrCode'
export default {
  // computed:{
  //   wxImg(){
	//       let hiramUrl = 'https://f1.hiram.cn/assets/img/wxImg.jpg'
  //     let ddgoldUrl = 'https://www.ddgold.cn/assets/img/zsERP2.jpg'
  //     let isTest = location.host.includes('hiram.cn')
  //     return isTest ? hiramUrl : ddgoldUrl
  //   }
  // }
  components : {
    qrCode
  }
}
</script>

<style scoped>
.side-bar {
  width: 66px;
  height: 66px;
  position: fixed;
  bottom: 400px;
  right: 25px;
  font-size: 0;
  line-height: 0;
  z-index: 100;
}
.side-bar .fa {
  font-size: 45px;
  line-height: 66px;
  color: #ffffff;
  padding-left: 8px;
}
.side-bar .icon-chat {
  width: 66px;
  height: 66px;
  display: inline-block;
  background-color: #524f45;
  margin-bottom: 2px;
  background-position: 0 -130px;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
}
.side-bar .icon-chat:hover {
  background-color: #0f69c9;
}
.side-bar .icon-chat:hover .chat-tips {
  display: block;
}
.chat-tips {
  padding: 20px;
  border: 1px solid #d1d2d6;
  position: absolute;
  border-radius: 5px;
  right: 78px;
  top: -55px;
  background-color: #ffffff;
  display: none;
}
.chat-tips i {
  width: 9px;
  height: 16px;
  display: inline-block;
  position: absolute;
  right: -9px;
  top: 80px;
  background-position: -88px -350px;
}
.chat-tips img {
  width: 138px;
  height: 138px;
}
.chat-tips h5 {
  color: #000 !important;
  line-height: 16px;
  height: 16px;
  font-size: 14px;
  text-align: center;
}
</style>
