<!-- 文本框 -->
<template>
  <div class="text-wrap">
    <el-input :item="item"
      :dataSource="dataSource"
      :readonly="readonly"
      :disabled="disabled"
      @change="handleChange"
      v-model="dataSource[item.data]">
    </el-input>
    <span class="suffix">{{item.suffix}}</span>
  </div>
</template>

<script>
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'itemValidate', 'handleChangeData'],
  data () {
    return {
    }
  },

  computed: {
  },

  methods: {
    handleChange(){
      if (this.item.limit) {
        this.dataSource[this.item.data] = this.checkData(this.dataSource[this.item.data])
      }

      this.handleChangeData(this.dataSource[this.item.data])
    },
    // 校验数据 校验通过返回data 不通过返回defaultValue
    checkData (data) {
      const limit = this.item.limit
      const dataType = limit.dataType // 限制类型
      const rules = limit.rules // 限制规则
      const defaultValue = limit.defaultValue // 不满足限制要求时的默认值
      const allowedSpecialValues = this.item.allowedSpecialValues // 允许的特殊值

      if (allowedSpecialValues && Array.isArray(allowedSpecialValues) && allowedSpecialValues.includes(data)) {
        return data
      }

      if (dataType && rules && Array.isArray(rules) && rules.length && defaultValue !== undefined && defaultValue !== null) {
        if (dataType === 'number') {
          data = Number(data)
          // 不是数字
          if (!data && data !== 0) {
            return defaultValue
          }

          for (let i = 0; i <  rules.length; i++) {
            if (rules[i].type === 'ge' && !(data >= rules[i].value)) {
              return defaultValue
            } else if (rules[i].type === 'le' && !(data <= rules[i].value)) {
              return defaultValue
            } else if (rules[i].type === 'gt' && !(data > rules[i].value)) {
              return defaultValue
            } else if (rules[i].type === 'lt' && !(data < rules[i].value)) {
              return defaultValue
            }
          }
        }
      }
      return data
    }
  }
}

</script>
<style scoped>
</style>
