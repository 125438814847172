<template>
  <div>
    <div class="print_container"
      :style="{
        'font-family': 'Arial, Microsoft Yahei, Helvetica Neue, Helvetica, sans-serif',
        padding: '3mm', // 20
        border: '1px solid #efefef',
        fontSize: '3.2mm', // 16
        lineHeight: '4.2mm', // 16
        overflow: 'auto',
        boxSizing: 'border-box',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        height: '45mm', //  1毫米 约等于 3.78像素
        width: '90mm', //  1毫米 约等于 3.78像素
      }">
      <div :style="{float:'left'}">
        <div :style="{marginBottom: '1mm'}">{{personInfo.user_name}} 专属条码： </div>
         <img :src="personInfo.barCodeUrl"
          :style="{height:'10mm'}">
      </div>

      <div :style="{float:'left',marginLeft: '2mm', paddingLeft: '2mm', borderLeft: '0.2mm solid #eee'}">
        <img :src="personInfo.corp_logo || personInfo.bloc_logo"
          v-if="personInfo.corp_logo || personInfo.bloc_logo"
          :style="{height: '7.2mm'}">
        <div :style="{fontSize: '4mm', margin: '1mm 0mm 1mm'}">{{personInfo.user_name}}</div>
        <div v-if="personInfo.tel">{{personInfo.tel}}</div>
        <div v-if="personInfo.corp_name">{{personInfo.corp_name}}</div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: ['personInfo']
}
</script>
