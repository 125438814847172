import Vuex from 'vuex'
import commonState from './common/state'
import commonMutation from './common/mutation'
import commonAction from './common/action'
import commonGetters from './common/getters'
import file from '@/file/vuex/store'

export default () => {
  const store = new Vuex.Store({
    // strict: process.env.NODE_ENV !== 'production',
    strict: false,
    state: commonState,
    mutations: commonMutation,
    actions: commonAction,
    getters: commonGetters,
    modules: {
      file
    }
  })
  return store
}
