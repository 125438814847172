import bus from '@/utils/bus'
import _ from 'lodash'

export default {
  setConfig (state, data) {
    state.config = data
  },
  setErrMsg (state, data) {
    state.errMsg = data
    state.configError = true
  },
  setMenu (state, data) {
    for (let i = 0; i < data.menu.length; i++) {
      const item = data.menu[i]
      // console.log(item)
      if (!item.nodes) {
        bus.$set(state.menu, item.primaryKey, _.cloneDeep(item))
      } else if (item.nodes) {
        for (let k = 0; k < item.nodes.length; k++) {
          const subItem = item.nodes[k]
          // console.log(subItem)
          bus.$set(state.menu, subItem.primaryKey, _.cloneDeep(subItem))
        }
      }
    }
  },
  // 外部 file 特殊方法，指定特定的file
  setOneMenu (state, { data, menuType }) {
    bus.$set(state.menu, menuType, data)
  },

  // 外部 file 特殊方法，把config挂到file里
  setFileConfig (state, {data}) {
    state.config = _.cloneDeep(data)
  },
  setMenuConfig (state, { data, menuType }) {
    bus.$set(state.menuConfig, menuType, data)
    // 初始化formStyle
    if (!state.formStyle[menuType]) {
      bus.$set(state.formStyle, menuType, data.style.formStyle)
    }
  },
  // 新模式写入source
  editSource (state, { data, menuType }) {
    bus.$set(state.menuConfig[menuType], 'source', !_.isEmpty(data.source) ? data.source : {})
  },
  setFormStyle (state, { formStyleType, menuType }) {
    bus.$set(state.formStyle, menuType, state.menuConfig[menuType].style[formStyleType])
  },
  setTableHeader (state, {headers, menuType}) {
    bus.$set(state.menuConfig[menuType].style, 'tableHeader', headers)
  },

  setTreeList (state, { data, menuType }) {
    let _data = data.treeView ? data.treeView : []
    bus.$set(state.treeList, menuType, _data)
    bus.$set(state.treeTotal, menuType, data.total ? parseInt(data.total) : 0)
  },
  setTableList (state, { data, menuType }) {
    let _data = data.data ? data.data : []
    // 需特殊处理的，如果多了就封装起来
    if(menuType === 'wxblocuser'){
      _data = _data.map(item => {
        item.wxblocuser_id = item.user_id
        return item
      })
    }
    bus.$set(state.tableList, menuType, _data)
    bus.$set(state.tableTotal, menuType, data.total ? parseInt(data.total) : 0)
  },
  updateSelectedId (state, { menuType, selectedId }) {
    bus.$set(state.selectedId, menuType, selectedId)
  },
  updateLoadTree (state, { menuType, status }) {
    bus.$set(state.loadTree, menuType, status)
  },
  // 更新表单内容
  setFormData (state, { menuType, data }) {
    let allowData = {}
    if (state.formStyle[menuType] && state.formStyle[menuType].length > 0) {
      let formStyle = state.formStyle[menuType].map(item => item.data)
      // data.filter(item=>{
      //   return formStyle.includes(item.)
      // })
      for (const key in data) {
        if (data.hasOwnProperty(key) && formStyle.includes(key)) {
          bus.$set(allowData, key, data[key])
        }
      }
    } else {
      allowData = data
    }

    bus.$set(state.formData, menuType, allowData)
  },
  // 更新表单是否被编辑
  setTreeEditing (state, { menuType, status }) {
    bus.$set(state.treeEditing, menuType, status)
  }
}
