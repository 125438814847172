<script>
import priceRules from './priceRules.vue'
export default {
  extends: priceRules,
  data () {
    return {
      open: false,
      values: [],
      options: [
        { value: 'stockcate_id', label: '存货分类' },
        { value: 'lustre_id', label: '成色' },
        { value: 'cusbase_id', label: '客户' },
        { value: 'child_cusbase', label: '子客户' },
        { value: 'product_name', label: '产品' },
        { value: 'product_code', label: '产品编码' },
      ]
    }
  },
}
</script>
<style scoped></style>
