<template>
    <div>
        <p>已取消作废</p>
        <p>点击“同步取消作废”后，将取消作废所有关联单据数据</p>
    </div>
</template>

<script>
export default {
    props: {
        info: Object
    }
}
</script>
