import { render, staticRenderFns } from "./planOverWgt.vue?vue&type=template&id=bb7cb9aa&scoped=true"
import script from "./planOverWgt.vue?vue&type=script&lang=js"
export * from "./planOverWgt.vue?vue&type=script&lang=js"
import style0 from "./planOverWgt.vue?vue&type=style&index=0&id=bb7cb9aa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.11_css-loader@6.11.0_webpack@5.95.0__lodash@4.17.21__jwnxr7kw2qy3fk4ahrxeovtfxy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb7cb9aa",
  null
  
)

export default component.exports