<template>
  <el-select v-model="selectedValue"
    filterable
    :readonly="readonly"
    :clearable="item.allowEmpty !== false"
    :disabled="disabled || readonly"
    @change="handleSelected"
    :placeholder="'请选择' + (item.name || item.colName)">
    <template v-if="source">
      <el-option v-for="sourceItem in filterSource"
        :key="sourceItem.id"
        :label="(sourceItem.value ? sourceItem.value + '  '  : '')+ sourceItem.text"
        :value="sourceItem.id">
      </el-option>
    </template>
  </el-select>
</template>

<script>
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'source', 'itemValidate', 'handleChangeData', 'resetDataTime'],
  data () {
    return {
      selectedValue: ''
    }
  },

  computed: {
    filterSource () {
      if (this.item.allowEmpty === false && this.source[this.item.source].data) {
        return this.source[this.item.source].data.filter(item => item.id !== '' && item.id !== '0000' && item.id !== 'null')
      } else {
        return this.source[this.item.source].data
      }
    }
  },
  methods: {
    handleSelected () {
      let id = this.selectedValue
      let data = this.source[this.item.source].data.filter(item => {
        return item.id === id
      })[0]

      // 后台要求是传特定格式才能改
      if (!data) {
        data = {
          id: null,
          text: null,
          value: null
        }
      }

      if (this.item.requestFormat) {
        let requestData
        if (this.item.requestFormat.type === 'single') {
          requestData = data[this.item.requestFormat.prop]
        } else if (this.item.requestFormat.type === 'object') {
          requestData = {}
          for (let prop of this.item.requestFormat.prop) {
            requestData[prop.name] = data[prop.data]
          }
        }

        // 处理未定义和空值的情况
        if (requestData === undefined || requestData === null) {
          requestData = ''
        }
        this.handleChangeData(requestData)
      } else {
        this.handleChangeData(data)
      }
    }
  },
  mounted () {
    if (this.item.responseData === 'self') {
        this.selectedValue = this.dataSource[this.item.data] ? this.dataSource[this.item.data] : ''
      } else {
        this.selectedValue = this.dataSource[this.item.data] ? this.dataSource[this.item.data].id : ''
      } 
  },
  watch: {
    resetDataTime () {
      if (this.item.responseData === 'self') {
        this.selectedValue = this.dataSource[this.item.data] ? this.dataSource[this.item.data] : ''
      } else {
        this.selectedValue = this.dataSource[this.item.data] ? this.dataSource[this.item.data].id : ''
      }  
    }
  }
}

</script>
<style scoped>
</style>
