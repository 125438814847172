var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full",style:(`background: #e6f2ff url(${_vm.$store.getters.bgUrl}) no-repeat center center;background-size: cover;`)},[_c('div',{staticClass:"box"},[_c('h1',{staticClass:"box-title"},[_c('Logo')],1),_c('div',{staticClass:"box-main"},[_c('div',[_c('img',{attrs:{"src":_vm.src}}),_c('div',{staticClass:"margin-top-20"},[_vm._v("请使用微信扫一扫")])]),(false)?_c('div',{staticClass:"userTab"},[_c('el-form',{ref:"loginForm",attrs:{"label-width":"0","model":_vm.registerData}},[_c('el-form-item',{attrs:{"label":"","prop":"corp_name","rules":[
              { required: true, message: '请输入公司名字', trigger: 'blur' },
            ]}},[_c('el-input',{attrs:{"placeholder":"请输入公司名字","size":"large"},on:{"blur":_vm.handleCorpBlur},model:{value:(_vm.registerData.corp_name),callback:function ($$v) {_vm.$set(_vm.registerData, "corp_name", $$v)},expression:"registerData.corp_name"}})],1),_c('el-form-item',{attrs:{"label":"","prop":"bloc_name","rules":[
              { required: true, message: '请输入集团名字', trigger: 'blur' },
            ]}},[_c('el-input',{attrs:{"placeholder":"请输入集团名字","size":"large"},on:{"blur":_vm.handleBlocBlur},model:{value:(_vm.registerData.bloc_name),callback:function ($$v) {_vm.$set(_vm.registerData, "bloc_name", $$v)},expression:"registerData.bloc_name"}})],1),_c('el-form-item',{attrs:{"label":"","prop":"tel","rules":[
              { required: true, message: '请输入手机号码', trigger: 'blur' },
              _vm.validates.tel,
            ]}},[_c('el-input',{attrs:{"placeholder":"请输入手机号码","size":"large"},model:{value:(_vm.registerData.tel),callback:function ($$v) {_vm.$set(_vm.registerData, "tel", $$v)},expression:"registerData.tel"}})],1),_c('el-form-item',{attrs:{"prop":"captcha","rules":[
              { required: true, message: '请输入验证码', trigger: 'blur' },
              _vm.validates.captcha,
            ]}},[_c('el-input',{attrs:{"placeholder":"请输入验证码","name":"captcha","size":"large","auto-complete":"on"},model:{value:(_vm.registerData.captcha),callback:function ($$v) {_vm.$set(_vm.registerData, "captcha", $$v)},expression:"registerData.captcha"}}),_c('span',{staticClass:"captcha-img"},[_c('img',{attrs:{"src":_vm.captchaUrl,"title":"点击刷新验证码","alt":""},on:{"click":_vm.refreshCaptcha}})])],1)],1),_c('el-form',{ref:"loginForm2",attrs:{"label-width":"0","model":_vm.registerData}},[_c('el-form-item',{attrs:{"prop":"smsCode","rules":[
              {
                required: true,
                message: '请输入短信验证码',
                trigger: 'blur',
              },
            ]}},[_c('el-input',{attrs:{"placeholder":"请输入短信验证码","name":"smsCode","size":"large","auto-complete":"on"},model:{value:(_vm.registerData.smsCode),callback:function ($$v) {_vm.$set(_vm.registerData, "smsCode", $$v)},expression:"registerData.smsCode"}}),_c('span',{staticClass:"captcha-img"},[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.sended),expression:"sended"}],staticStyle:{"width":"120px","height":"38px"},attrs:{"type":"text"},on:{"click":_vm.sendSmsCode}},[_vm._v("获取验证码")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sended),expression:"!sended"}],staticStyle:{"width":"120px","height":"38px"},attrs:{"type":"text"}},[_vm._v(_vm._s(_vm.time)+"秒后重新获取")])],1)],1)],1),_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","size":"large"},on:{"click":_vm.doSubmit}},[_vm._v("注 册")])],1):_vm._e()]),_c('div',{staticClass:"login-select"},[_c('div',{staticClass:"login-select-text"},[_c('div',{staticClass:"text",on:{"click":_vm.goLogin}},[_vm._v("已有帐号，去登录 >>")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }