var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"left"},[_c('Logo',{attrs:{"height":46}})],1),(_vm.loginData)?_c('div',{staticClass:"right"},[_vm._v(" 帐套： "+_vm._s(_vm.loginData.bloc.text)+" "),_c('span',{staticClass:"back",on:{"click":function($event){_vm.backToLogin ? _vm.handleBackToLogin() : _vm.handleBack()}}},[_vm._v("返回")])]):_vm._e()]),(_vm.loginData)?_c('div',{staticClass:"page"},[_c('img',{attrs:{"src":_vm.loginData.img.src,"alt":""}}),_c('ul',{staticClass:"box"},_vm._l((_vm.loginData.corp),function(item){return _c('li',{key:item.id,class:{
          disabled: _vm.notAllow(item)
        },attrs:{"title":item.date ? `有效期至${_vm.parseTime(item.date, '{y}年{m}月{d}日')}，${_vm.lastTime(item)}。` : ''},on:{"click":function($event){return _vm.handleSelectlogin(item)}}},[_vm._v(" "+_vm._s(item.text)+" "),(_vm.notAllow(item))?_c('span',{style:({
          color: 'red'
        })},[_vm._v("（"+_vm._s(_vm.lastTime(item))+"）")]):(_vm.in30Day(item))?_c('span',{style:({
          color: '#e6a23c'
        })},[_vm._v("（"+_vm._s(_vm.lastTime(item))+"）")]):_vm._e()])}),0)]):_vm._e(),_c('div',{staticClass:"footer"},[_vm._v("威库科技")]),_c('wechatQrcode')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }