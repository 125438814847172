<!-- 文本框 -->
<template>

  <img-upload :uploadType="1"
    :fileList="dataSource[item.data]"
    :editing="!(readonly || disabled)"
    :params="params"
    @changeImage="handleChangeData"></img-upload>

</template>

<script>
import imgUpload from './components/imgUpload'
import { mapState } from 'vuex'
// import { stateInfo } from '@/file/utils/stateInfo'
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'itemValidate', 'handleChangeData', 'menuType', 'actionType'],
  data () {
    return {
      tempFiles: [],
      // 目前只有公司、集团档案，以下值都是null
      params: {
        action: undefined,
        images_source_id: undefined,
        state: undefined
      }
    }
  },
  components: {
    imgUpload
  },
  computed: {
    ...mapState('file', ['menu'])
  },
  methods: {
    handleChangeImage (data) {
      this.handleChangeData(data)
    }
  },
  mounted () {
    // this.uploadData.action = this.actionType
    // if (this.actionType === 'add') {
    //   this.params.images_source_id = undefined
    // } else {
    //   let idField = this.menu[this.menuType]['primaryKey'] + '_id'
    //   this.params.images_source_id = 'p' + (this.dataSource.id || this.dataSource[idField])
    // }
    // this.params.state = stateInfo(this.menuType)
  }
}

</script>

<style scoped>
</style>
