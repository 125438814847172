<!-- 开关 -->
<template>

    <el-switch :readonly="readonly"
      :disabled="readonly || disabled"
      v-model="dataSource[item.data]"
      :active-value="item.translate ? item.translate.true : 1"
      :inactive-value="item.translate ? item.translate.false : 0">
    </el-switch>
</template>

<script>
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'itemValidate'],
  data () {
    return {
    }
  },

  computed: {
  },

  methods: {}
}

</script>
<style scoped>
</style>
