<template>
  <div class="flex flex-column flex-center flex-middle" v-if="data">
    <Logo :height="46"></Logo>
    <div style="margin: 16px" v-if="!inNode && data.url && data.file">
      <el-button type="primary"
        icon="el-icon-download"
        @click="handleClick"
        :title="data.PC_version ? `PC端软件版本 ${data.PC_version}` : ''"
        size="large">PC端下载</el-button>
    </div>
    <div style="font-size: 14px;" v-if="!inNode && data.url && data.file">
      PC端软件版本：{{data.PC_version}}
    </div>
    <div style="font-size: 14px;" v-if="data.WEB_version">
      订单平台系统版本：{{data.WEB_version}}
    </div>
    <div style="color:#ccc;font-size: 12px;">
      Copyright &copy; 2018 威库科技
    </div>
  </div>
</template>

<script>
import Logo from '@/components/logo.vue'
import { mapState } from 'vuex';
import {http} from '~utils'
import { inNode } from "@/utils/env"

export default {
  components: {
    Logo
  },
  computed: {
    ...mapState(['config'])
  },
  data() {
    return {
      data: null,
      inNode: inNode
    }
  },
  created(){
    this.loadData()
  },
  methods: {
    handleClick(e){
      e && e.currentTarget && e.currentTarget.blur()
      let url = this.data.url + '/' + this.data.file
      window.open(url)
    },
    loadData() {
      http({
        method: 'get',
        noToUrl: true,
        url:
          process.env.NODE_ENV === "production"
            ? "latest.php"
            : "../static/latest.json",
        noErrorDialog: true
      }).then(
        data => {
          this.data = data
        },
        errData => {
        }
      );
    }
  }


}
</script>

<style scoped>
.flex > div {
  margin-bottom: 10px;
}
</style>

