<!-- 文本框 -->
<template>

    <el-checkbox-group v-model="selectedValue"
      :disabled="readonly || disabled"
      @input="handleSelected"
      :readonly="readonly">
      <el-checkbox :label="sourceItem.id"
        v-for="sourceItem in source[item.source].data"
        :key="sourceItem.id">{{(sourceItem.value || '') + '  ' + sourceItem.text}}</el-checkbox>
    </el-checkbox-group>
</template>

<script>
export default {
  props: ['item', 'dataSource', 'readonly', 'disabled', 'source', 'resetDataTime', 'itemValidate', 'handleChangeData'],
  data () {
    return {
      selectedValue: ''
    }
  },
  methods: {
    setSelectedValue () {
      let val = []
      this.dataSource[this.item.data].forEach(item => {
        val.push(item.id)
      })
      this.selectedValue = val
    },
    handleSelected () {
      let ids = this.selectedValue
      let data = this.source[this.item.source].data.filter(item => {
        return ids.includes(item.id)
      })
      // console.log('handleSelected', data)

      this.handleChangeData(data)
    }
  },
  mounted () {
    this.setSelectedValue()
  },
  watch: {
    resetDataTime () {
      this.setSelectedValue()
    }
  }
}

</script>
<style scoped>
</style>
