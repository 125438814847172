import { apiHttp } from '@/utils/query/token'
import { baseModel } from './baseModel'

/**
 * 消息通知
 */
export class messagelist extends baseModel {
    static messageType = {
        'abolishStatusChanged': '作废状态变更',
        'deliveryDateChanged': '交货日期变更',
        'returnOrder': '转单状态变更',
        'saleOrderStatusChanged': '销售订单状态变更',
        'unabolishStatusChanged': '取消作废状态变更',
        'deliveryDateChangedResult': '交货日期变更结果',
        'applyForWorkshopChanged': '申请修改生产线',
        'applyForWorkshopChangedResult': '申请修改生产线结果',
    }

    /**
     * 修改生产线
     */
    static changeWorkshop (id) {
        return apiHttp({
            url: 'messagelist/changeworkshop?id=' + id,
            method: 'get',
        })
    }

    static productionStatusLabel = {
        '0': '计划生产',
        '10': '生产订单未审核',
        '20': '生产订单已审核',
        '30': '工序单未投产',
        '40': '工序单已投产',
        '50': '核销收货中',
        '60': '核销完成',
    }
}