<template>
  <el-popover placement="bottom-start"
    width="210"
    @hide="handleCancel"
    v-model="visible">

    <el-select v-model="filterMethod"
      style="width:100%;"
      placeholder="过滤条件">
      <el-option-group v-for="(item,key) in (item.type === 'date' ? dateOptions : options)"
        :key="key">
        <el-option :label="son.label"
          v-for="son in item"
          :key="son.value"
          :value="son.value"></el-option>
      </el-option-group>
    </el-select>

    <el-input placeholder="起始值"
      v-model="filterContent"
      v-if="!['empty','not_empty','none'].includes(filterMethod)"
      style="width:100%;margin-top:10px;"
      class="input-with-select">
    </el-input>

    <el-input placeholder="末值"
      v-model="filterContentEnd"
      v-if="['between'].includes(filterMethod)"
      style="width:100%;margin-top:10px;"
      class="input-with-select">
    </el-input>

    <el-input placeholder="搜索值"
      style="width:100%;margin-top:10px;"
      prefix-icon="el-icon-search"
      size="mini"
      v-model="filterText">
    </el-input>
    <div style="text-align:right">
      <el-button type="text"
        @click="allChecked">选择全部</el-button>
      <el-button type="text"
        @click="resetChecked">清除所有</el-button>
    </div>
    <div class="node-list">
      <!-- @check-change="handleCheckChange"   -->
      <el-tree :data="containCells"
        node-key="data"
        class="filter-box-list"
        :props="defaultProps"
        :filter-node-method="filterNode"
        default-expand-all
        :highlight-current="true"
        show-checkbox
        check-strictly
        @node-click="handleNodeCheck"
        @check="handleCheckChange"
        ref="tree">
        <span class="custom-tree-node"
          style="display:inline-block"
          slot-scope="{ node, data }">
          <span>{{ data.data || '[空白]'}}</span>
        </span>
      </el-tree>
    </div>

    <div style="text-align: right; margin: 0;margin-top:10px;">
      <el-button size="mini"
        type="text"
        @click="handleCancel">取消</el-button>
      <el-button type="primary"
        size="mini"
        :disabled="this.filterMethod === 'none' && selectedArr.length === 0"
        @click="handleFilterSearch">确定</el-button>
    </div>

    <button :class="['changeType',{active: active}]"
      slot="reference"
      @click="handleFilterOpen"></button>

  </el-popover>
</template>

<script>
import {
  mapState
// mapActions,
// mapMutations
} from 'vuex'
import utils from '@/utils'
import bus from '@/utils/bus'
import _ from 'lodash'
export default {
  name: 'filterBox',
  props: ['menuType', 'field', 'url', 'item', 'paginationOptions', 'lastFilterField'],
  data () {
    return {
      special: false, // 之前有没请求过
      visible: false,
      active: false,
      filterMethod: 'none',
      filterContent: null,
      filterContentEnd: null,
      containCells: [], // 所有数据
      filterArr: [],
      filterText: '',
      selectedArr: [],
      defaultProps: {
        children: 'nodes', // 目前都不存在children
        label: 'data'
      },
      tempStatus: {},
      options: [
        [{ label: '无', value: 'none' }],
        [{ label: '空', value: 'empty' }, { label: '非空', value: 'not_empty' }],
        [{ label: '等于', value: 'eq' }, { label: '不等于', value: 'neq' }],
        // [{ label: '开始以', value: 'begins_with' }, { label: '结束以', value: 'ends_with' }],
        [{ label: '包含', value: 'contains' }, { label: '不包含', value: 'not_contains' }]
      ],
      dateOptions: [
        [{ label: '无', value: 'none' }],
        [{ label: '空', value: 'empty' }, { label: '非空', value: 'not_empty' }],
        [{ label: '等于', value: 'eq' }, { label: '不等于', value: 'neq' }],
        [{ label: '之前', value: 'date_before' }, { label: '之后', value: 'date_after' }, { label: '之间', value: 'between' }], // between 2个值，起始值，末值
        [{ label: '明天', value: 'date_tomorrow' }, { label: '今天', value: 'date_today' }, { label: '昨天', value: 'date_yesterday' }]
      ]
    }
  },

  components: {},

  computed: {
    ...mapState('file', ['selectedId','menu'])
  },

  destroyed () {
    bus.$off('removeAllFilter', this.removeAllFilter)
  },

  mounted () {
    let _this = this
    bus.$on('removeAllFilter', this.removeAllFilter)
  },
  watch: {
    filterText: {
      handler (val) {
        this.filterArr = [] // 重置选中的内容
        if (!this.filterText) {
          this.filterArr = this.containCells.map(item => {
            return { data: item.data }
          })
          this.resetChecked()
        }
        this.$refs.tree.filter(val)
      },
      immediate: false
    }
  },
  methods: {
    filterNode (value, data) {
      if (!value) return true
      // 强制转字符串，避免后台给非字符串格式
      let text = String(data.data)
      // 判断text里有没此值
      if (text.indexOf(value) !== -1) {
        // let arr = []
        this.filterArr.push({
          data: text
        })
        // this.$refs.tree.setCheckedNodes(this.filterArr)
        this.setCheckedNodes(this.filterArr)
        return true
      } else {
        return false
      }
    },
    resetChecked () {
      // this.filterArr = []
      // this.$refs.tree.setCheckedKeys([])
      this.setCheckedNodes([])
    },
    allChecked () {
      // this.$refs.tree.setCheckedNodes(this.filterArr)
      this.setCheckedNodes(this.filterArr)
    },
    handleNodeClick (data, checked, indeterminate) {
      var index
      var checkArr = this.$refs.tree.getCheckedNodes()
      if (!checkArr.some((item, key) => {
        index = key
        return item.data === data.data
      })) {
        checkArr.push({
          data: data.data
        })
      } else {
        checkArr.splice(index, 1)
      }
      // this.$refs.tree.setCheckedNodes(checkArr)
      // this.selectedArr = checkArr
      console.log(checkArr)
      this.setCheckedNodes(checkArr)
    },
    handleNodeCheck (data, node) { // 手动点击时，操作选中状态
      console.log(node, data)
      this.$refs.tree.setChecked(node.data, !node.checked)
      // var checkArr = this.$refs.tree.getCheckedNodes()
      // console.log(checkArr)
      this.handleCheckChange()

      // this.setCheckedNodes(checkArr)
    },
    // handleCheckChange (data, checked, indeterminate) {
    //   var checkArr = this.$refs.tree.getCheckedNodes()

    //   this.setCheckedNodes(checkArr)
    // },
    handleCheckChange () {
      console.log('handleCheckChange')

      var checkArr = this.$refs.tree.getCheckedNodes()

      this.setCheckedNodes(checkArr)
    },
    setCheckedNodes (arr) {
      this.$refs.tree.setCheckedNodes(arr)
      this.selectedArr = arr
      // console.log(this.selectedArr)
    },
    // 点击确定
    handleFilterSearch () {
      let containCellValue = this.selectedArr.map(item => item.data)
      // console.log(containCellValue)

      let obj = {
        containCellValue: containCellValue, // 勾选选中的值
        filterCondition: {
          // contains: 0 // 对应关系: 值
        }
      }
      if (this.filterMethod) {
        // [this.filterContent,this.filterContentEnd]
        obj.filterCondition[this.filterMethod] = this.filterMethod !== 'between' ? this.filterContent : [this.filterContent, this.filterContentEnd]
      }

      // 判断，过滤条件为无+过滤项全选，视为没有操作过过滤
      if (this.filterMethod === 'none' && this.containCells.every(item => {
        return obj.containCellValue.includes(item.data)
      })) {
        // 视为没有操作过过滤，active设置为false，给父组件抛obj为null，父组件再把这个字段删除；
        console.log('没有操作')
        this.$emit('handleFilterSearch', null, this.field)
        this.active = false
      } else {
        // console.log(obj)
        this.$emit('handleFilterSearch', obj, this.field)
        if (this.active === false) {
          this.active = true
        }
      }

      // 记录状态
      this.setTempStatus()
      // 关闭窗口
      this.visible = false
    },
    removeAllFilter (menuType) {
      if (menuType === this.menuType) {
        this.tempStatus = {}
        this.filterText = ''
        this.active = false
        this.special = false
        this.containCells = []
        this.initFilter()
      }
    },
    handleCancel () {
      // 还原状态
      this.goBackTempStatus()

      // 关闭窗口
      this.visible = false
    },
    setTempStatus () {
      this.$set(this.tempStatus, 'selectedArr', this.selectedArr)
      this.$set(this.tempStatus, 'filterText', this.filterText)
      this.$set(this.tempStatus, 'filterArr', this.filterArr)
      this.$set(this.tempStatus, 'filterMethod', this.filterMethod)
      this.$set(this.tempStatus, 'filterContent', this.filterContent)
      this.$set(this.tempStatus, 'filterContentEnd', this.filterContentEnd)
    },
    goBackTempStatus () {
      // this.$refs.tree.setCheckedNodes(this.tempStatus.selectedArr)
      this.setCheckedNodes(this.tempStatus.selectedArr)
      this.filterArr = this.tempStatus.filterArr
      this.filterText = this.tempStatus.filterText
      this.filterMethod = this.tempStatus.filterMethod
      this.filterContent = this.tempStatus.filterContent
      this.filterContentEnd = this.tempStatus.filterContentEnd
    },
    // 开启，包括第一次初始化
    handleFilterOpen () {

      // 如果上次操作的不是自己，需初始化
      if (!this.containCells.length || (this.lastFilterField && this.lastFilterField !== this.field)) {
        // 加载数据
        let data = {
          filterColName: this.field,
          paginationOptions: this.paginationOptions,
          special: this.special,
          selectId: this.selectedId[this.menuType],
          _tabId: this.menu[this.menuType].menuId
        }
        utils.http({
          url: this.url + (process.env.NODE_ENV === 'production' ? '/distinctcol' : '/distinctcol.json'),
          data: data,
          needLoading: false
        })
          .then(res => {
            // 空值转[空白]，不需过滤空值
            // this.containCells = res.data ? res.data.filter(item => {
            //   return !!item.data
            // }) : []
            // 处理 null 空值
            this.containCells = res.data.map(item => {
              return item.data === null ? {
                selected: item.selected,
                data: ''
              } : item
            })

            this.initFilter()

            // 记录过不是第一次
            this.special = true
          })
      }
    },
    initFilter () {
      this.filterText = ''
      // 所有选项

      this.filterArr = _.cloneDeep(this.containCells)

      let selected = this.filterArr.filter(item => {
        return item.selected
      })

      this.$nextTick(() => {
        // 选中全部该选中的
        // this.$refs.tree.setCheckedNodes(selected)
        this.setCheckedNodes(selected)
        // 记录初始状态
        this.setTempStatus()
      })
    }
  }
}

</script>
<style scoped>
.node-list {
  margin: 10px 0;
  height: 240px;
  overflow: auto;
}
.node-list >>> .el-tree-node__expand-icon {
  display: none;
}
.el-select-group__wrap:not(:last-of-type)::after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  bottom: 5px;
  height: 1px;
  background: #e4e7ed;
}
.el-select-group__wrap:not(:last-of-type) {
  padding-bottom: 10px;
}

.el-select-dropdown__item {
  height: 22.5px;
  line-height: 22.5px;
}

.el-select-dropdown__item >>> span {
  line-height: 22.5px !important;
}
.changeType {
  background: #fff;
  border-radius: 2px;
  border: 1px solid #bbb;
  color: #bbb;
  font-size: 12px;
  line-height: 12px;
  padding: 1px 2px;
  display: inline;
  margin: 0px 1px 0 5px;
  cursor: pointer;
}
.changeType:before {
  content: "\25BC ";
}

.changeType.active {
  border: 1px solid #509272;
  color: #18804e;
}
.changeType:focus {
  outline: 0;
}
</style>

<style>
.el-popover.el-popper {
  transform: translate(-12px, 0) !important;
}

.filter-box-list .el-tree-node__content>.el-checkbox {
  margin-left: 6px;
  margin-right: 6px;
}
</style>
