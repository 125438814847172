export function afterLogOut () {
  window.localStorage.blocId = null;
  delete window.localStorage.corpId;
  delete window.localStorage.corpName;
  delete window.localStorage.corp_shortname;
}

export function afterLogIn (corpId, corpName, blocId) {
  // delete window.localStorage.blocId;
  window.localStorage.corpId = corpId;
  window.localStorage.corpName = corpName;
  window.localStorage.blocId = blocId;
  window.localStorage["ngStorage-stateMenuVer"] = new Date().getTime();
}
export function checkCorp (corpId, corpName) {
  window.localStorage["ngStorage-stateMenuVer"] = new Date().getTime();
  if (window.localStorage.corpId === undefined || window.localStorage.corpId !== corpId || window.localStorage.corpName !== corpName) {
    // delete window.localStorage.blocId;
    window.localStorage.corpId = corpId;
    window.localStorage.corpName = corpName;
  }
}
